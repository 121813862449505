import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import 'typeface-roboto';
import App from './App';
import './index.scss';
// import reportWebVitals from './reportWebVitals';
import * as Ant from 'antd';
import {useCookies} from 'react-cookie';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import LoginPage from './user/login/LoginPage';

import {NavigationLocations} from './NavigationAware';
import {ChatAssistantPage} from './chat/page/ChatAssistantPage';
import {NewChatService} from './chat/service/ChatService';
import {ADAPTIFY_TENANT_ID} from './common/service/Constants';
import {AnalysisStudyDetailPage} from './comparative-analysis/page/AnalysisStudyDetailPage';
import {AnalysisStudyListPage} from './comparative-analysis/page/AnalysisStudyListPage';
import {CreateTableComparisonPage} from './comparative-analysis/page/CreateTableComparisonPage';
import {TableComparisonDetailPage} from './comparative-analysis/page/TableComparisonDetailPage';
import NewAnalysisService from './comparative-analysis/service/AnalysisService';
import {TrialAccountPage} from './crm/page/CreateTrialAccountPage';
import {TrialAccountDetailPage} from './crm/page/TrialAccountDetailPage';
import {TrialAccountListPage} from './crm/page/TrialAccountListPage';
import NewTrialAccountService from './crm/service/TrialAccountService';
import {LobCoverageFieldPage} from './lob/page/LobCoverageFieldPage';
import {LobHierarchyPage} from './lob/page/LobHierarchyPage';
import {LobItemPage} from './lob/page/LobItemPage';
import {LobRiskFieldPage} from './lob/page/LobRiskFieldPage';
import NewLobService from './lob/service/LobService';
import {TenantMode} from './product/page/ProductOverviewControl';
import {ProductOverviewPage} from './product/page/ProductOverviewPage';
import {ProductVersionDetailPage} from './product/page/ProductVersionDetailPage';
import NewProductService from './product/service/ProductService';
import {ProductVersionCoveragePage} from './rating/page/ProductVersionCoveragePage';
import {ProductVersionLobCoverageFieldPage} from './rating/page/ProductVersionLobCoverageFieldPage';
import {ProductVersionLobItemPage} from './rating/page/ProductVersionLobItemPage';
import {ProductVersionLobRiskFieldPage} from './rating/page/ProductVersionLobRiskFieldPage';
import {ProductVersionPremiumOrchestrationPage} from './rating/page/ProductVersionPremiumOrchestrationPage';
import {ProductVersionRiskFlowPage} from './rating/page/ProductVersionRiskFlowPage';
import {ProductVersionTableDiffPage} from './rating/page/ProductVersionTableDiffPage';
import {ProductVersionTablePage} from './rating/page/ProductVersionTablePage';
import {ProductVersionTestCaseDetailPage} from './rating/page/ProductVersionTestCaseDetailPage';
import {ProductVersionTestCasePage} from './rating/page/ProductVersionTestCasePage';
import NewRatingService from './rating/service/RatingService';
import UserContext from './user/context/UserContext';
import {ChangePasswordPage} from './user/login/ChangePasswordPage';
import {TenantModuleName} from './user/model/Tenant';
import {UserInfo} from './user/model/User';
import {TenantDetailPage} from './user/page/TenantDetailPage';
import {TenantSummaryPage} from './user/page/TenantSummaryPage';
import NewTenantService from './user/service/TenantService';
import NewUserService from './user/service/UserService';
import NewAuthManager from './user/util/AuthManager';
import {ValueListDefinitionPage} from './valuelist/page/ValueListDefinitionPage';
import {ValueListItemPage} from './valuelist/page/ValueListItemPage';
import {NewValueListService} from './valuelist/service/ValueListService';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export default function MyApp() {
  // TODO move this logic out of index and into App.tsx, which requires refactoring these routes out of this file

  const [authCookie, setAuthCookie] = useCookies(['adaptify_auth_token']);

  const [authTokenCounter, setAuthTokenCounter] = useState(0);
  // if we aren't authorized on initial render, show login screen, otherwise show a login popup
  // so work isn't lost if the token expires

  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

  const authManager = NewAuthManager({
    authCookie,
    setAuthCookie,
    authChanged: (token: string, userLogout: boolean) => {
      setAuthTokenCounter(authTokenCounter + 1);
      if (userLogout) {
        // if the user logged out, show the login page instead of rending the app UI and login as popup
        setShowLoginPage(true);
      } else if (token !== '') {
        // if the user logged in, hide the login page and show subsequent login as popup
        setShowLoginPage(false);
        setUserModifyCount(userModifyCount + 1);
      }
    },
    authorities: userInfo?.authorities,
  });

  const [showLoginPage, setShowLoginPage] = useState(!authManager.IsLoggedIn());

  const [userModifyCount, setUserModifyCount] = useState(0);

  useEffect(() => {
    if (authManager.IsLoggedIn()) {
      const eff = async () => {
        const loaded = await userService.GetUserDetails();
        setUserInfo(loaded);
      };
      eff();
    }
  }, [authManager.IsLoggedIn(), userModifyCount]);

  const lobService = authManager.CreateTokenRefreshingServiceProxy(
    NewLobService(authManager.GetAuthCookieValue())
  );

  const productService = authManager.CreateTokenRefreshingServiceProxy(
    NewProductService(authManager.GetAuthCookieValue())
  );

  const ratingService = authManager.CreateTokenRefreshingServiceProxy(
    NewRatingService(authManager.GetAuthCookieValue())
  );

  const analysisService = authManager.CreateTokenRefreshingServiceProxy(
    NewAnalysisService(authManager.GetAuthCookieValue())
  );

  const valueListService = authManager.CreateTokenRefreshingServiceProxy(
    NewValueListService(authManager.GetAuthCookieValue())
  );

  const tenantService = authManager.CreateTokenRefreshingServiceProxy(
    NewTenantService(authManager.GetAuthCookieValue())
  );

  const userService = authManager.CreateTokenRefreshingServiceProxy(
    NewUserService(authManager.GetAuthCookieValue())
  );

  const chatService = authManager.CreateTokenRefreshingServiceProxy(
    NewChatService(authManager.GetAuthCookieValue())
  );

  const trialAccountService = authManager.CreateTokenRefreshingServiceProxy(
    NewTrialAccountService(authManager.GetAuthCookieValue())
  );

  const isAdaptifyUser = authManager.GetTenantId() === ADAPTIFY_TENANT_ID;
  const authorities = authManager.GetAuthorities() ?? [];

  const analysisRoutes = (
    <Route
      path="/analysis"
      element={
        <App
          authToken={authManager.GetAuthCookieValue()}
          setAuthToken={authManager.SetAuthToken}
          logout={authManager.Logout}
          title="COMPARATIVE ANALYSIS"
          authManager={authManager}
        />
      }
    >
      <Route
        path="/analysis/study/list"
        element={
          <AnalysisStudyListPage
            analysisService={analysisService}
            productService={productService}
            lobService={lobService}
            valueListService={valueListService}
            authToken={authManager.GetAuthCookieValue()}
          />
        }
      />
      <Route
        path="/analysis/study/:studyId/detail"
        element={
          <AnalysisStudyDetailPage
            analysisService={analysisService}
            productService={productService}
            valueListService={valueListService}
            lobService={lobService}
          />
        }
      />
      <Route
        path="/analysis/study/:studyId/createtablecomparison"
        element={
          <CreateTableComparisonPage
            analysisService={analysisService}
            productService={productService}
          />
        }
      />
      <Route
        path="/analysis/studytablecomparison/:tableComparisonId/detail"
        element={
          <TableComparisonDetailPage
            analysisService={analysisService}
            productService={productService}
          />
        }
      />
    </Route>
  );
  const analysisAuthRoutes = authorities.includes(
    TenantModuleName.ComparativeAnalysis
  ) ? (
    analysisRoutes
  ) : (
    <></>
  );

  const trialAccountRoutes = (
    <>
      <Route
        path="/platform/trialaccount/list"
        element={
          <TrialAccountListPage
            trialAccountService={trialAccountService}
            currentNavigation={NavigationLocations.Users}
          />
        }
      />
      <Route
        path="/platform/trialaccount/detail/:trialAccountId"
        element={
          <TrialAccountDetailPage
            trialAccountService={trialAccountService}
            currentNavigation={NavigationLocations.Users}
          />
        }
      />
    </>
  );
  const trialAccountAuthRoutes = authorities.includes(
    TenantModuleName.TrialAccounts
  ) ? (
    trialAccountRoutes
  ) : (
    <></>
  );

  const chatRoutes = (
    <Route
      path="/platform/chat"
      element={<ChatAssistantPage chatService={chatService} />}
    />
  );
  const chatAuthRoutes = authorities.includes(
    TenantModuleName.RateProductAssistant
  ) ? (
    chatRoutes
  ) : (
    <></>
  );

  const tenantRoutes = (
    <>
      <Route
        path="/platform/tenant/summary"
        element={
          <TenantSummaryPage
            tenantService={tenantService}
            valueListService={valueListService}
            userService={userService}
            currentNavigation={NavigationLocations.Users}
            userTenantId={authManager.GetTenantId()}
          />
        }
      />
      <Route
        path="/platform/tenant/detail/:tenantId"
        element={
          <TenantDetailPage
            tenantService={tenantService}
            valueListService={valueListService}
            userService={userService}
            currentNavigation={NavigationLocations.Users}
            userTenantId={authManager.GetTenantId()}
          />
        }
      />
    </>
  );
  const tenantAuthRoutes = authorities.includes(
    TenantModuleName.AccountProvisioning
  ) ? (
    tenantRoutes
  ) : (
    <></>
  );

  const valueListRoutes = (
    <>
      {' '}
      <Route
        path="/platform/valuelist/summary"
        element={
          <ValueListDefinitionPage
            valueListService={valueListService}
            currentNavigation={NavigationLocations.Lists}
          />
        }
      />
      <Route
        path="/platform/valuelist/:valueDefinitionId/item"
        element={
          <ValueListItemPage
            valueListService={valueListService}
            currentNavigation={NavigationLocations.Lists}
          />
        }
      />{' '}
      <Route
        path="/platform/valuelist/summary"
        element={
          <ValueListDefinitionPage
            valueListService={valueListService}
            currentNavigation={NavigationLocations.Lists}
          />
        }
      />
      <Route
        path="/platform/valuelist/:valueDefinitionId/item"
        element={
          <ValueListItemPage
            valueListService={valueListService}
            currentNavigation={NavigationLocations.Lists}
          />
        }
      />
    </>
  );
  const valueListAuthRoutes = authorities.includes(TenantModuleName.Lists) ? (
    valueListRoutes
  ) : (
    <></>
  );

  const clientRateProductLookupRoutes = (
    <Route
      path="/platform/product/summary/all"
      element={
        <ProductOverviewPage
          lobService={lobService}
          productService={productService}
          valueListService={valueListService}
          tenantService={tenantService}
          currentNavigation={NavigationLocations.Rating}
          authToken={authManager.GetAuthCookieValue()}
          authManager={authManager}
          adaptifyTenantMode={TenantMode.All}
        />
      }
    />
  );

  const clientRateProductLookupAuthRoutes = authorities.includes(
    TenantModuleName.ClientRateProductLookup
  ) ? (
    clientRateProductLookupRoutes
  ) : (
    <></>
  );

  const loggedInRoutes = (
    <>
      <UserContext.Provider value={authManager.GetLoginUserId()}>
        <Routes>
          <Route
            path="/login"
            element={
              <LoginPage
                storeAuthToken={authManager.SetAuthToken}
                redirectToUrl="/"
              />
            }
          />
          <Route path="/trialaccount/create" element={<TrialAccountPage />} />
          {/* this is redundant with the platform route, should be cleaned up later to organize better*/}
          <Route
            path="/"
            element={
              <App
                authToken={authManager.GetAuthCookieValue()}
                setAuthToken={authManager.SetAuthToken}
                logout={authManager.Logout}
                title="PLATFORM ADMINISTRATION"
                authManager={authManager}
              />
            }
          >
            <Route
              path="/"
              element={
                <Navigate to="/platform/product/summary/my" replace={true} />
              }
            />
          </Route>
          <Route
            path="/platform"
            element={
              <App
                authToken={authManager.GetAuthCookieValue()}
                setAuthToken={authManager.SetAuthToken}
                logout={authManager.Logout}
                title="PLATFORM ADMINISTRATION"
                authManager={authManager}
              />
            }
          >
            <Route
              path="/platform/lob/hierarchy"
              element={
                <LobHierarchyPage
                  lobService={lobService}
                  valueListService={valueListService}
                  currentNavigation={NavigationLocations.LOB}
                  isAdaptifyUser={isAdaptifyUser}
                  currentTenantId={authManager.GetTenantId() ?? ''}
                />
              }
            />
            <Route
              path="/platform/lob/:lobHierarchyId/risk/field/"
              element={
                <LobRiskFieldPage
                  lobService={lobService}
                  currentNavigation={NavigationLocations.LOB}
                  currentTenantId={authManager.GetTenantId() ?? ''}
                />
              }
            />
            <Route
              path="/platform/lob/:lobDefId/risk/"
              element={
                <LobItemPage
                  lobService={lobService}
                  currentNavigation={NavigationLocations.LOB}
                  currentTenantId={authManager.GetTenantId() ?? ''}
                />
              }
            />
            <Route
              path="/platform/lob/:lobHierarchyId/coverage/field"
              element={
                <LobCoverageFieldPage
                  lobService={lobService}
                  currentNavigation={NavigationLocations.LOB}
                  currentTenantId={authManager.GetTenantId() ?? ''}
                />
              }
            />
            <Route
              path="/platform"
              element={
                <Navigate to="/platform/product/summary/my" replace={true} />
              }
            />
            {chatAuthRoutes}
            <Route
              path="/platform/product/summary/adaptify"
              element={
                <ProductOverviewPage
                  lobService={lobService}
                  productService={productService}
                  valueListService={valueListService}
                  tenantService={tenantService}
                  currentNavigation={NavigationLocations.Rating}
                  authToken={authManager.GetAuthCookieValue()}
                  authManager={authManager}
                  adaptifyTenantMode={TenantMode.Adaptify}
                />
              }
            />
            <Route
              path="/platform/product/summary/my"
              element={
                <ProductOverviewPage
                  lobService={lobService}
                  productService={productService}
                  valueListService={valueListService}
                  tenantService={tenantService}
                  currentNavigation={NavigationLocations.Rating}
                  authToken={authManager.GetAuthCookieValue()}
                  authManager={authManager}
                  adaptifyTenantMode={TenantMode.Tenant}
                />
              }
            />
            <Route
              path="/platform/productversion/:productId/detail"
              element={
                <ProductVersionDetailPage
                  productService={productService}
                  valueListService={valueListService}
                  ratingService={ratingService}
                  authManager={authManager}
                  currentNavigation={NavigationLocations.Rating}
                />
              }
            />
            {tenantAuthRoutes}
            {trialAccountAuthRoutes}
            {valueListAuthRoutes}
            {clientRateProductLookupAuthRoutes}
          </Route>
          <Route
            path="/rating"
            element={
              <App
                authToken={authManager.GetAuthCookieValue()}
                setAuthToken={authManager.SetAuthToken}
                logout={authManager.Logout}
                title="ADAPTIFY RATING EDITOR"
                authManager={authManager}
              />
            }
          >
            <Route
              path="/rating/product/version/:productVersionId/table"
              element={
                <ProductVersionTablePage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  authToken={authManager.GetAuthCookieValue()}
                  currentNavigation={NavigationLocations.Rating}
                  authManager={authManager}
                />
              }
            />
            <Route
              path="/rating/product/version/:productVersionIdOne/:productVersionIdTwo/tablediff"
              element={
                <ProductVersionTableDiffPage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  authToken={authManager.GetAuthCookieValue()}
                  currentNavigation={NavigationLocations.Rating}
                  authManager={authManager}
                />
              }
            />
            <Route
              path="/rating/product/version/:productVersionId/coverage"
              element={
                <ProductVersionCoveragePage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  authToken={authManager.GetAuthCookieValue()}
                  currentNavigation={NavigationLocations.Rating}
                  authManager={authManager}
                />
              }
            />
            <Route
              path="/rating/product/version/:productVersionId/risk"
              element={
                <ProductVersionRiskFlowPage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  authToken={authManager.GetAuthCookieValue()}
                  currentNavigation={NavigationLocations.Rating}
                  authManager={authManager}
                />
              }
            />
            <Route
              path="/rating/product/version/:productVersionId/orchestration"
              element={
                <ProductVersionPremiumOrchestrationPage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  currentNavigation={NavigationLocations.Rating}
                  authManager={authManager}
                />
              }
            />
            <Route
              path="/rating/product/version/:productVersionId/lob"
              element={
                <ProductVersionLobItemPage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  authManager={authManager}
                  currentNavigation={NavigationLocations.Rating}
                />
              }
            />
            <Route
              path="/rating/product/version/:productVersionId/lob/risk"
              element={
                <ProductVersionLobRiskFieldPage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  authManager={authManager}
                  currentNavigation={NavigationLocations.Rating}
                />
              }
            />
            <Route
              path="/rating/product/version/:productVersionId/lob/coverage"
              element={
                <ProductVersionLobCoverageFieldPage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  authManager={authManager}
                  currentNavigation={NavigationLocations.Rating}
                />
              }
            />
            <Route
              path="/rating/product/version/:productVersionId/testcase"
              element={
                <ProductVersionTestCasePage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  authManager={authManager}
                  currentNavigation={NavigationLocations.Rating}
                  authToken={''}
                />
              }
            />
            <Route
              path="/rating/testcase/:testCaseId/detail"
              element={
                <ProductVersionTestCaseDetailPage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  currentNavigation={NavigationLocations.Rating}
                  authManager={authManager}
                />
              }
            />
          </Route>
          {analysisAuthRoutes}
        </Routes>
      </UserContext.Provider>
    </>
  );

  const loggedOutRoutes = (
    <Routes>
      <Route path="/trialaccount/create" element={<TrialAccountPage />} />
      <Route
        path="*"
        element={
          <Ant.App>
            <LoginPage
              storeAuthToken={authManager.SetAuthToken}
              redirectToUrl="/"
            />
          </Ant.App>
        }
      />
    </Routes>
  );

  const passwordNeedsChangeRoutes = (
    <Routes>
      <Route
        path="*"
        element={
          <App
            authToken={authManager.GetAuthCookieValue()}
            setAuthToken={authManager.SetAuthToken}
            logout={authManager.Logout}
            title="PLATFORM ADMINISTRATION"
            authManager={authManager}
          />
        }
      >
        <Route
          path="*"
          element={
            <ChangePasswordPage
              currentNavigation={NavigationLocations.LOB}
              userService={userService}
              onPasswordChanged={() => {
                setUserModifyCount(userModifyCount + 1);
              }}
            />
          }
        />
      </Route>
    </Routes>
  );

  const finalRoutes =
    showLoginPage || !authManager.IsLoggedIn()
      ? loggedOutRoutes
      : userInfo?.changePasswordNeeded
        ? passwordNeedsChangeRoutes
        : loggedInRoutes;

  return <BrowserRouter>{finalRoutes}</BrowserRouter>;
}

root.render(
  <React.StrictMode>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
    <Ant.ConfigProvider
      theme={{
        token: {
          fontFamily: 'Roboto, sans-serif',
        },
        components: {
          Layout: {
            headerBg: '#000000',
            headerColor: '#ffffff',
            algorithm: true,
          },
          Select: {
            colorBorder: '#ACB7C6',
          },
          Button: {
            primaryColor: '#ffffff',
            colorPrimary: '#019ED9',
            defaultActiveBg: '#00aeef',
            defaultActiveColor: '#ffffff',
            defaultGhostColor: '#ffffff',
            defaultColor: '#ffffff',
            defaultBg: '#00aeef',
            defaultHoverBg: '#049ed7',
            defaultHoverColor: '#ffffff',
          },
          Menu: {
            colorPrimary: '#019ED9',
          },
          Form: {
            colorPrimary: '#019ED9',
          },
        },
      }}
    >
      <MyApp />
    </Ant.ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
