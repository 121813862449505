import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import {Button, Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {DraggableModal} from '../../common/control/DraggableModal';
import {DateIsoStringToDisplayString} from '../../common/model/CommonUtils';
import {ProductVersionSummary} from '../model/Product';
import {ProductService} from '../service/ProductService';

export interface ProductVersionPopupForDiffProps {
  productService: ProductService;
  productId: string | undefined;
  onCancelProductVersionPick: () => void;
  onConfirmProductVersionPick: () => void;
  setSelectedProductVersionIds: (selection: string[]) => void;
  defaultSelectedProductVersionId: string;
}

export function ProductVersionPopupForDiff(
  props: ProductVersionPopupForDiffProps
) {
  const [productVersions, setProductVersions] = useState<
    ProductVersionSummary[]
  >([]);
  const [selectedProductVersionIds, setSelectedProductVersionIds] = useState<
    string[]
  >([]);
  const [validationOpen, setValidationOpen] = useState(false);

  useEffect(() => {
    if (!props.productId) {
      setProductVersions([]);
    }

    const eff = async () => {
      if (!props.productId) {
        setProductVersions([]);
        return;
      }

      const loaded = await props.productService.QueryProductVersionSummaries({
        productId: props.productId ?? '',
      });
      setProductVersions(loaded);
      setSelectedProductVersionIds([props.defaultSelectedProductVersionId]);
    };
    eff();
  }, [props.productId]);

  const colDefs: GridColDef<ProductVersionSummary>[] = [
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      flex: 200,
      valueGetter: (value, row) => {
        if (!row.effectiveDate) {
          return '';
        }
        return DateIsoStringToDisplayString(row.effectiveDate);
      },
    },
    {
      field: 'versionNumber',
      headerName: 'Version',
      flex: 150,
      valueGetter: (value, row) => {
        if (!row.versionNumber) {
          return '';
        }
        return row.versionNumber;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 200,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 400,
    },
  ];

  function onProductVersionPickChanged(
    rowSelectionModel: GridRowSelectionModel
  ) {
    setSelectedProductVersionIds(rowSelectionModel as string[]);
    props.setSelectedProductVersionIds(rowSelectionModel as string[]);
  }

  function onConfirmProductVersionPick(): void {
    if (selectedProductVersionIds.length !== 2) {
      setValidationOpen(true);
      return;
    }

    props.onConfirmProductVersionPick();
  }

  const validationModal = validationOpen ? (
    <DraggableModal
      className="adaptify-modal"
      open={validationOpen}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 800px)'}
      footer={null}
    >
      <Flex vertical gap={20}>
        <div style={{width: '100%'}}>
          2 versions needed to be selected in order to initiate the difference
          between version process
        </div>
        <div className="flex justify-center items-center">
          <Button
            type="default"
            onClick={() => {
              setValidationOpen(false);
            }}
          >
            OK
          </Button>
        </div>
      </Flex>
    </DraggableModal>
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical gap={20}>
        <div style={{height: '100%', width: '100%'}}>
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            {...DataGridSettings}
            columns={colDefs}
            rows={productVersions}
            rowSelectionModel={selectedProductVersionIds}
            onRowSelectionModelChange={onProductVersionPickChanged}
            sx={{
              '&.MuiDataGrid-root': {
                borderRadius: '8px',
                overflow: 'hidden',
                borderColor: '#CCCCCC',
              },
            }}
          />
        </div>
        <div className="flex justify-center items-center">
          <Button
            {...ButtonSettings}
            htmlType="button"
            onClick={props.onCancelProductVersionPick}
          >
            Cancel
          </Button>
          <Button
            ghost={false}
            type="default"
            onClick={onConfirmProductVersionPick}
          >
            OK
          </Button>
        </div>
      </Flex>
      {validationModal}
    </>
  );
}
