import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {
  Button,
  Col,
  Flex,
  Input,
  Popconfirm,
  Row,
  Space,
  Typography,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {DraggableModal} from '../../common/control/DraggableModal';
import {
  LineOfBusinessItem,
  LobHierarchyInfo,
  LobItemCardinality,
  LobItemInfo,
} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';
import {EditLobRiskForm} from './EditLobRiskForm';

export interface LobRiskListProps {
  lobService: LobService;
  lobDefId: string | undefined;
  selection: string | undefined;
  selectionChanged: (selection: string | undefined) => void;
  existingItemsReadOnly?: boolean;
  readOnly?: boolean;
  modifyCount: number;
  setModifyCount: (count: number) => void;
  getDrilldownUrl(lobItemId: string): string | undefined;
}

export interface EditState {
  isNew: boolean;
  isEditing: boolean;
  lobItemId?: string;
  openCount: number;
}

export function LobRiskList(props: LobRiskListProps) {
  const [editState, setEditState] = useState<EditState>({
    isNew: false,
    isEditing: false,
    openCount: 0,
  });
  const [lob, setLob] = useState<LobHierarchyInfo | undefined>(undefined);

  const [risks, setRisks] = useState<LobItemInfo[]>([]);

  useEffect(() => {
    if (props.lobDefId === '') {
      setRisks([]);
      return;
    }
    const eff = async () => {
      if (!props.lobDefId) {
        return;
      }

      const lobHierarchy = await props.lobService.GetLobDefInfoById(
        props.lobDefId
      );
      setLob(lobHierarchy);

      const risks = await props.lobService.GetLineOfBusinessRisks(
        props.lobDefId
      );
      setRisks(risks);
      if (risks.length > 0 && !props.selection) {
        props.selectionChanged(risks[0].id);
      }
    };
    eff();
  }, [props.lobDefId, props.modifyCount]);

  const allColDefs: GridColDef<LobItemInfo>[] = [
    {
      field: 'parentLineOfBusinessItemName',
      headerName: 'Parent Risk Item',
      flex: 300,
    },
    {
      field: 'name',
      headerName: 'Risk Item Name',
      flex: 300,
      renderCell: params => {
        const url = props.getDrilldownUrl(params.row.id);
        if (!url) {
          {
            params.row.name;
          }
        }
        return <Link to={url}>{params.row.name}</Link>;
      },
    },
    {
      field: 'cardinality',
      headerName: 'Cardinality',
      valueGetter: (value, row) => {
        const cardinality = row?.cardinality;
        if (cardinality === LobItemCardinality.Single.name) {
          return 'Single';
        }
        if (cardinality === LobItemCardinality.Many.name) {
          return 'Multi';
        }
        return '';
      },
      flex: 150,
    },
  ];

  const colDefs = allColDefs;

  function onOpenCreateDialog() {
    setEditState({
      isNew: true,
      isEditing: true,
      openCount: editState.openCount + 1,
    });
  }

  function onOpenEditDialog() {
    const sel = getSelectedRisk();
    if (!sel) {
      return;
    }
    setEditState({
      isNew: true,
      isEditing: true,
      lobItemId: sel.id,
      openCount: editState.openCount,
    });
  }

  async function onDelete() {
    const sel = getSelectedRisk();
    if (!sel) {
      return;
    }
    await props.lobService.DeleteLineOfBusinessItem(sel.id);
    props.setModifyCount(props.modifyCount + 1);
  }

  async function onSaveLineOfBusinessEntity(value: LineOfBusinessItem) {
    setEditState({
      ...editState,
      isEditing: false,
    });
    props.setModifyCount(props.modifyCount + 1);
  }

  function onCancelLineOfBusinessEntity() {
    setEditState({
      ...editState,
      isEditing: false,
    });
  }

  const deleteButton = (
    <Popconfirm
      title="Confirm deletion"
      description="Selecting the “OK” button will permanently remove this risk item.  Proceed?"
      onConfirm={onDelete}
      disabled={!getSelectedRisk()}
      okText="Delete"
      okType="default"
      cancelText="Cancel"
    >
      <Button
        {...ButtonSettings}
        disabled={!getSelectedRisk()?.parentLineOfBusinessItemId}
      >
        Delete
      </Button>
    </Popconfirm>
  );

  const editButtons = (
    <Flex justify="flex-end" align="flex-start" className="gap-2">
      {deleteButton}
      <Button
        {...ButtonSettings}
        onClick={onOpenEditDialog}
        disabled={
          props.existingItemsReadOnly ||
          // can't modify root
          !getSelectedRisk()?.parentLineOfBusinessItemId ||
          getSelectedRisk()?.copiedToClientFromAdaptify
        }
      >
        Modify
      </Button>
      <Button
        {...ButtonSettings}
        onClick={onOpenCreateDialog}
        disabled={props.lobDefId === ''}
      >
        Add Risk Item
      </Button>
    </Flex>
  );

  const buttons = props.readOnly ? <></> : editButtons;

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    const selection: LineOfBusinessItem | undefined = undefined;
    if (rowSelectionModel.length !== 0) {
      props.selectionChanged(rowSelectionModel[0] as string);
    } else {
      props.selectionChanged(undefined);
    }
  }

  function getSelectedRisk() {
    if (props.selection) {
      return risks.find(r => r.id === props.selection);
    }
    return undefined;
  }

  const inputStyle = {background: '#EEEEEE'};

  return (
    <>
      <Flex vertical style={{width: '100%', height: '100%'}}>
        <Row>
          <Col span={7}>
            <div className="page-title">Risk Items</div>
          </Col>
          <Col span={2}>
            <Typography.Text strong>Hierarchy Name</Typography.Text>
          </Col>
          <Col span={9}>
            <Input readOnly style={inputStyle} value={lob?.name} />
          </Col>
          <Col span={6}>{buttons}</Col>
        </Row>
        <Space direction="vertical" style={{width: '100%', height: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={risks}
              rowSelectionModel={props.selection ? [props.selection] : []}
              onRowSelectionModelChange={onTableSelectionChanged}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </div>
        </Space>
      </Flex>
      <DraggableModal
        className="adaptify-modal"
        title={editState.isNew ? 'Add Risk' : 'Edit Risk'}
        open={editState.isEditing}
        closable={false}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        width={'clamp(300px, 70svw, 800px)'}
        footer={null}
      >
        <EditLobRiskForm
          lobService={props.lobService}
          lobId={props.lobDefId}
          existingItemId={editState.lobItemId}
          onSave={onSaveLineOfBusinessEntity}
          onCancel={onCancelLineOfBusinessEntity}
          formOpenCount={editState.openCount}
        />
      </DraggableModal>
    </>
  );
}
