package com.adaptify.rating.admin.model.lob

import com.adaptify.rating.admin.model.MetadataObject
import kotlin.js.JsExport

expect interface LineOfBusinessHierarchyItem : MetadataObject {
  var itemType : String
  var parentLineOfBusinessItemId : String?
  var children: Array<LineOfBusinessHierarchyItem>
  var fields: Array<LineOfBusinessHierarchyItemField>
  var isMany: Boolean
}

expect fun createLineOfBusinessHierarchyItem(
  id: String,
  name: String,
  version: Int,
  itemType : String,
  parentLineOfBusinessItemId : String?,
  children: Array<LineOfBusinessHierarchyItem>,
  fields: Array<LineOfBusinessHierarchyItemField>,
  isMany: Boolean
): LineOfBusinessHierarchyItem

class LineOfBusinessHierarchyItemImpl(
  override var id: String,
  override var name: String,
  override var version: Int,
  override var itemType :String,
  override var parentLineOfBusinessItemId : String?,
  override var children: Array<LineOfBusinessHierarchyItem>,
  override var fields: Array<LineOfBusinessHierarchyItemField>,
  override var isMany: Boolean
) : LineOfBusinessHierarchyItem

@JsExport
object LineOfBusinessHierarchyItemUtil {
  fun create(
    id: String,
    name: String,
    version: Int,
    itemType: String,
    parentLineOfBusinessItemId : String?,
    children: Array<LineOfBusinessHierarchyItem>,
    fields: Array<LineOfBusinessHierarchyItemField>,
    isMany: Boolean
  ): LineOfBusinessHierarchyItem {
    return createLineOfBusinessHierarchyItem(id, name, version, itemType, parentLineOfBusinessItemId,
      children, fields, isMany)
  }
  fun deepCopy(obj: LineOfBusinessHierarchyItem): LineOfBusinessHierarchyItem {
    return create(
      obj.id,
      obj.name,
      obj.version,
      obj.itemType,
      obj.parentLineOfBusinessItemId,
      obj.children.map { LineOfBusinessHierarchyItemUtil.deepCopy(it)}.toTypedArray(),
      obj.fields.map { LineOfBusinessHierarchyItemFieldUtil.deepCopy(it) }.toTypedArray(),
      obj.isMany
    )
  }
}
