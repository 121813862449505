import {SendOutlined, StopOutlined} from '@ant-design/icons';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {Avatar, Button, Input, InputRef} from 'antd';
import {AnimatePresence, motion} from 'framer-motion';
import React, {useLayoutEffect, useRef, useState} from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {ConfirmationModal} from '../../common/control/ConfirmationModal';
import {StreamState} from '../hook/useStream';
import {
  Conversation,
  Message,
  MetadataDetails,
  SelectedConversation,
  SendMessageArgs,
} from '../model/Chat';
enum SuggestionFilter {
  CarrierFilter = 'CarrierFilter',
  LOBFilter = 'LOBFilter',
  StateFilter = 'StateFilter',
}
export interface ChatControlProps {
  selectedConversation: SelectedConversation;
  setSelectedConversation: React.Dispatch<
    React.SetStateAction<SelectedConversation>
  >;
  loading: boolean;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  conversationState: StreamState<SendMessageArgs>;
  ingestionMetadata: MetadataDetails;
  remoteConversations: Conversation[];
  deleteAllConversations: () => void;
}
export const ChatControl: React.FC<ChatControlProps> = ({
  selectedConversation,
  setSelectedConversation,
  loading,
  messages,
  setMessages,
  conversationState,
  ingestionMetadata,
  remoteConversations,
  deleteAllConversations,
}: ChatControlProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({block: 'end', behavior: 'smooth'});
  };
  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);
  const [userInput, setUserInput] = useState<string>('');
  const [deleteConversationsConfirmOpen, setDeleteConversationsConfirmOpen] =
    useState(false);
  const [selectedCarrierSuggestion, setSelectedCarrierSuggestion] =
    useState<string>('');
  const [selectedCarrierLOBSuggestion, setSelectedCarrierLOBSuggestion] =
    useState<string>('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [position, setPosition] = useState({top: 0, left: 0, width: 0});
  const [selectedCarrierFilter, setSelectedCarrierFilter] =
    useState<string>('');
  const [selectedCarrierLOBFilter, setSelectedCarrierLOBFilter] =
    useState<string>('');
  const [selectedStateFilter, setSelectedStateFilter] = useState<string>('');

  const handleSendMessage = () => {
    if (conversationState.isActive || !userInput.trim()) return;
    if (filteredSuggestions.length > 0) {
      handleSuggestionClick(filteredSuggestions[0]);
      return;
    }
    const formattedUserInput = getFormattedUserInputAfterApplyingFilter();
    const userMessage: Message = {
      id: crypto.randomUUID(),
      role: 'user',
      content: userInput,
    };
    setMessages(prev => [...prev, userMessage]);
    setUserInput('');
    const metadata = generateMetadataFromUserQuery(formattedUserInput);
    conversationState.startStream({
      message: formattedUserInput,
      conversationId: selectedConversation?.id,
      metadata: metadata,
    });
  };

  const generateMetadataFromUserQuery = (formattedUserInput): string => {
    let metadataString = '';
    if (
      selectedStateFilter &&
      selectedCarrierLOBFilter &&
      selectedStateFilter
    ) {
      const queryFilter = {
        user_query: formattedUserInput,
        metadata: {
          state: selectedStateFilter,
          carrier: selectedCarrierFilter,
          underwriting_company: '',
          insurance_type: getInsuranceType(selectedCarrierLOBFilter),
          line_of_business: getLOB(selectedCarrierLOBFilter),
        },
      };
      metadataString = JSON.stringify(queryFilter);
    }
    return metadataString;
  };

  const getInsuranceType = lobFilter => {
    return ingestionMetadata.companies[selectedCarrierFilter]
      ? Object.values(
          ingestionMetadata.companies[selectedCarrierFilter]?.lineOfBusiness ||
            {}
        ).find(lob => lob.displayValue)?.value
      : '';
  };

  const getLOB = lobFilter => {
    if (lobFilter.includes('Commercial')) {
      return lobFilter.replace('Commercial ', '');
    } else {
      return lobFilter;
    }
  };

  const getFormattedUserInputAfterApplyingFilter = (): string => {
    let formattedUserInput = userInput;
    if (selectedCarrierFilter && !userInput.includes(selectedCarrierFilter)) {
      formattedUserInput =
        formattedUserInput + ' from ' + selectedCarrierFilter;
    }
    if (selectedStateFilter && !userInput.includes(selectedStateFilter)) {
      formattedUserInput =
        formattedUserInput + ' in the state of ' + selectedStateFilter;
    }
    return formattedUserInput;
  };

  const userInputRef = useRef<InputRef>(null);
  const handleNewConversation = () => {
    setUserInput('');
    setMessages([]);
    setSelectedConversation(null);
    userInputRef.current?.focus();
  };
  const deleteConversationsConfirmModal = deleteConversationsConfirmOpen ? (
    <ConfirmationModal
      open={deleteConversationsConfirmOpen}
      message="Please confirm that you would like to delete all the conversation history for your login account"
      title="Delete Conversation History"
      onOk={() => {
        deleteConversations();
        setDeleteConversationsConfirmOpen(false);
      }}
      onCancel={() => setDeleteConversationsConfirmOpen(false)}
    />
  ) : (
    <> </>
  );
  const deleteConversations = () => {
    deleteAllConversations();
    window.location.reload();
    userInputRef.current?.focus();
  };
  const handleInputChange = text => {
    setUserInput(text);
    const suggestionList = checkForSuggestions(text);
    setFilteredSuggestions(suggestionList);
    if (suggestionList.length > 0) {
      calculateAndSetPostionofSuggestionBox();
    }
  };
  const checkForSuggestions = (text): string[] => {
    if (
      selectedCarrierSuggestion &&
      !text.includes(selectedCarrierSuggestion)
    ) {
      setSelectedCarrierSuggestion('');
    }
    if (
      selectedCarrierLOBSuggestion &&
      !text.includes(selectedCarrierLOBSuggestion)
    ) {
      setSelectedCarrierLOBSuggestion('');
    }
    const [suggestionFilter, filterType] = checkAtSymbolBeforeCursor();
    if (text.length > 0 && ingestionMetadata) {
      if (
        text.toLowerCase().endsWith('for @') ||
        text.toLowerCase().endsWith('from @') ||
        filterType === SuggestionFilter.CarrierFilter ||
        filterType === SuggestionFilter.LOBFilter
      ) {
        if (selectedCarrierSuggestion && !selectedCarrierLOBSuggestion) {
          return ingestionMetadata.companies[selectedCarrierSuggestion]
            ? Object.values(
                ingestionMetadata.companies[selectedCarrierSuggestion]
                  ?.lineOfBusiness || {}
              )
                .map(lob => lob.displayValue)
                .filter(name =>
                  suggestionFilter
                    ? name
                        .toLowerCase()
                        .includes(suggestionFilter.toLowerCase())
                    : true
                )
            : [];
        } else {
          return Object.keys(ingestionMetadata.companies).filter(name =>
            suggestionFilter
              ? name.toLowerCase().includes(suggestionFilter.toLowerCase())
              : true
          );
        }
      } else if (
        text.toLowerCase().endsWith('state @') ||
        filterType === SuggestionFilter.StateFilter
      ) {
        if (selectedCarrierSuggestion) {
          return getAvailableStatesForDropdown();
        }
      }
    }
    return [];
  };
  const checkAtSymbolBeforeCursor = (): [string, string] => {
    if (!userInputRef.current || !userInputRef.current.input) return ['', ''];
    let filterType = '';
    const inputElement = userInputRef.current.input;
    const cursorPos = inputElement.selectionStart || 0;
    const textBeforeCursor = inputElement.value.substring(0, cursorPos);
    const match = textBeforeCursor.match(/@([a-zA-Z0-9_]*)$/); // Match "@" followed by any word characters
    const textBeforeAtSymbol = match
      ? textBeforeCursor
          .substring(0, textBeforeCursor.length - match[1].length)
          .toLowerCase()
      : '';
    filterType = match
      ? textBeforeAtSymbol.endsWith('state @') ||
        textBeforeAtSymbol.endsWith('state of @')
        ? 'StateFilter'
        : textBeforeAtSymbol.endsWith('from @') ||
            (textBeforeAtSymbol.endsWith('for @') && selectedCarrierSuggestion)
          ? 'LOBFilter'
          : 'CarrierFilter'
      : '';
    return [match ? match[1] : '', filterType];
  };
  const handleSuggestionClick = text => {
    if (ingestionMetadata.companies[text]) {
      setSelectedCarrierSuggestion(text);
      setSelectedCarrierFilter(text);
    }
    if (
      selectedCarrierSuggestion &&
      ingestionMetadata.companies[selectedCarrierSuggestion] &&
      ingestionMetadata.companies[selectedCarrierSuggestion].lineOfBusiness[
        text
      ]
    ) {
      setSelectedCarrierLOBSuggestion(text);
      setSelectedCarrierLOBFilter(text);
    }
    if (
      selectedCarrierSuggestion &&
      ingestionMetadata.companies[selectedCarrierSuggestion] &&
      selectedCarrierLOBSuggestion &&
      ingestionMetadata.companies[selectedCarrierSuggestion].lineOfBusiness[
        selectedCarrierLOBSuggestion
      ][text]
    ) {
      setSelectedStateFilter(text);
    }
    const formattedInput = userInput
      .replace(checkAtSymbolBeforeCursor()[0], '')
      .replace('@', text);
    setUserInput(formattedInput.trimEnd());
    setFilteredSuggestions([]);
    userInputRef.current?.focus();
  };
  const calculateAndSetPostionofSuggestionBox = () => {
    if (userInputRef.current && userInputRef.current.input) {
      const rect = userInputRef.current.input.getBoundingClientRect();
      const selectionStart = userInputRef.current.input.selectionEnd || 0;
      // Create a hidden span to calculate exact cursor position
      const dummySpan = document.createElement('span');
      dummySpan.style.visibility = 'hidden';
      dummySpan.style.position = 'absolute';
      dummySpan.style.whiteSpace = 'pre';
      dummySpan.style.font = window.getComputedStyle(
        userInputRef.current.input
      ).font; // Copy input font styles
      dummySpan.textContent =
        userInputRef.current.input.value.substring(0, selectionStart) || ' ';
      document.body.appendChild(dummySpan);
      const caretX = dummySpan.offsetWidth;
      document.body.removeChild(dummySpan);
      setPosition({
        top: rect.bottom - window.scrollY - rect.height,
        left: rect.left + window.scrollX + caretX,
        width: rect.width,
      });
    }
  };

  const getAvailableLOBForDropdown = (): string[] => {
    return ingestionMetadata.companies[selectedCarrierFilter]
      ? Object.values(
          ingestionMetadata.companies[selectedCarrierFilter]?.lineOfBusiness ||
            {}
        ).map(lob => lob.displayValue)
      : [];
  };

  const getAvailableStatesForDropdown = (): string[] => {
    const data =
      (ingestionMetadata.companies[selectedCarrierFilter] &&
      ingestionMetadata.companies[selectedCarrierFilter].lineOfBusiness
        ? Object.values(
            ingestionMetadata.companies[selectedCarrierFilter].lineOfBusiness
          )
        : []
      ).find(b => b.displayValue === selectedCarrierLOBFilter)?.states || [];
    return data;
  };
  const stopStream = () => conversationState.stopStream();
  return (
    <>
      <div className="flex flex-1 flex-col max-h-[752px]">
        <div className="flex justify-between">
          <h1 className="page-title mb-4">Rate Product Assistant</h1>
          <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
            <Button
              type="primary"
              disabled={!selectedConversation || loading}
              ghost={true}
              icon={<AddOutlinedIcon />}
              onClick={handleNewConversation}
              aria-label="New Conversation"
            >
              New Conversation
            </Button>
            <Button
              type="primary"
              disabled={
                remoteConversations.length === 0 && messages.length === 0
              }
              ghost={true}
              icon={<DeleteOutlinedIcon />}
              onClick={() => setDeleteConversationsConfirmOpen(true)}
              aria-label="Delete Conversation"
            >
              Delete Conversation
            </Button>
          </div>
        </div>
        <div className="p-2 bg-gray-50 rounded-lg shadow-md w-full flex space-x-4 mb-[10px]">
          {/* Carrier Dropdown */}
          <select
            value={selectedCarrierFilter}
            onChange={e => setSelectedCarrierFilter(e.target.value)}
            className="w-full p-2 border rounded-lg"
          >
            <option value="">Select Carrier</option>
            {Object.keys(ingestionMetadata.companies).map(carrier => (
              <option key={carrier} value={carrier}>
                {carrier}
              </option>
            ))}
          </select>
          {/* LOB Dropdown */}
          <select
            value={selectedCarrierLOBFilter}
            onChange={e => setSelectedCarrierLOBFilter(e.target.value)}
            className="w-full p-2 border rounded-lg"
          >
            <option value="">Select Line Of Business</option>
            {getAvailableLOBForDropdown().map(lob => (
              <option key={lob} value={lob}>
                {lob}
              </option>
            ))}
          </select>
          {/* State Dropdown */}
          <select
            value={selectedStateFilter}
            onChange={e => setSelectedStateFilter(e.target.value)}
            className="w-full p-2 border rounded-lg"
          >
            <option value="">Select State</option>
            {getAvailableStatesForDropdown().map(state => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
        <div
          className="flex-1 overflow-y-auto mb-4 scroll-mb-4 p-4 scroll-p-4 bg-gray-50 border border-gray-200 rounded-lg"
          role="log"
          aria-label="Chat messages"
        >
          <AnimatePresence initial={false}>
            {messages.map(message => (
              <motion.div
                key={message.id}
                animate={{opacity: 1, y: 20}}
                className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}
              >
                <div
                  className={`flex ${message.role === 'user' ? 'flex-row-reverse' : 'flex-row'} items-start max-w-[80%]`}
                >
                  {message.role === 'assistant' && (
                    <Avatar
                      size={40}
                      src="/favicon.ico"
                      className="mx-2 flex-shrink-0"
                      alt="Assistant Avatar"
                    />
                  )}
                  <div
                    className={`flex flex-col ${message.role === 'user' ? 'items-end' : 'items-start'}`}
                  >
                    {message.role === 'assistant' && (
                      <span className="text-xs text-gray-500 mb-1">
                        Assistant
                      </span>
                    )}
                    <div
                      className={`p-3 rounded-lg ${message.role === 'user' ? 'bg-blue-500 text-white' : 'bg-white border border-gray-200'} shadow-sm`}
                    >
                      <Markdown
                        className={'markdown-container'}
                        children={message.content}
                        rehypePlugins={[rehypeRaw]}
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
            <div className="" ref={messagesEndRef} />
          </AnimatePresence>
          {filteredSuggestions.length > 0 && (
            <ul
              style={{
                position: 'absolute',
                top: `${position.top - 157}px`,
                left: `${position.left}px`,
                width: '240px',
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                borderRadius: '6px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                padding: '5px 5px',
                marginTop: '5px',
                zIndex: 1000,
                listStyle: 'none',
                maxHeight: '160px',
                minHeight: '150px',
                overflowY: 'auto',
                display: 'inline-block',
              }}
            >
              {filteredSuggestions.map((suggestion, index) => (
                <li
                  style={{
                    padding: '5px',
                    border: '1px solid #019ED9',
                    background: '#E6F7FF',
                    color: '#0175A5',
                    fontSize: '14px',
                    fontWeight: '500',
                    marginBottom: '5px',
                    transition: 'background 0.3s ease-in-out',
                  }}
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="flex items-center gap-2 p-4 bg-gray-50 border border-gray-200 rounded-lg">
          <Input
            value={userInput}
            onChange={e => handleInputChange(e.target.value)}
            onPressEnter={handleSendMessage}
            placeholder="Type your message here..."
            aria-label="Message input"
            className="flex-1"
            ref={userInputRef}
          />
          {conversationState.isActive ? (
            <Button
              disabled={!conversationState.isActive}
              type="primary"
              ghost={true}
              icon={<StopOutlined />}
              onClick={stopStream}
              aria-label="Stop Response"
            >
              Stop
            </Button>
          ) : (
            <Button
              disabled={loading || !userInput.trim()}
              type="primary"
              ghost={true}
              icon={<SendOutlined />}
              onClick={handleSendMessage}
              aria-label="Send Message"
            >
              Send
            </Button>
          )}
        </div>
      </div>
      {deleteConversationsConfirmModal}
    </>
  );
};
