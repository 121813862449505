import {Button, DatePicker, Flex, Form, Input, Select} from 'antd';
import dayjs from 'dayjs';
import React, {useState} from 'react';
import {
  ButtonSettings,
  DateDataFormats,
  StateCodeOptions,
} from '../../common/control/Common';
import {ErrorModal, ErrorModalState} from '../../common/control/ErrorModal';
import {DayJsToIsoString} from '../../common/model/CommonUtils';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {ProductVersion} from '../model/Product';
import {ProductService} from '../service/ProductService';

export interface CopyProductVersionFormProps {
  productService: ProductService;
  valueListService: ValueListService;
  productVersionId: string;
  productVersionEffectiveDate: string;
  productVersionStateCode: string;
  refreshFormCount: number;
  onFinish: (productVersion: ProductVersion) => void;
  onCancel: () => void;
}

interface CopyProductVersionFormValues {
  effectiveDate: dayjs.Dayjs;
  description: string;
  stateCode: string;
}

export function CopyProductVersionForm(props: CopyProductVersionFormProps) {
  const [form] = Form.useForm<CopyProductVersionFormValues>();

  const [errorModalState, setErrorModalState] = useState<ErrorModalState>({
    open: false,
    message: '',
  });

  function onFinish(value: CopyProductVersionFormValues) {
    onCopy(value);
  }

  async function onCopy(value: CopyProductVersionFormValues) {
    try {
      const pv = await props.productService.CopyProductVersion(
        props.productVersionId,
        DayJsToIsoString(value.effectiveDate),
        value.stateCode,
        value.description
      );
      props.onFinish(pv);
    } catch (error) {
      setErrorModalState({open: true, message: error.message});
    }
    return;
  }

  function effectiveDateStateValidator(_, value) {
    if (!value) return Promise.resolve();

    if (
      DayJsToIsoString(form.getFieldValue('effectiveDate')) ===
        props.productVersionEffectiveDate &&
      form.getFieldValue('stateCode') === props.productVersionStateCode
    ) {
      return Promise.reject(
        'effective date and state code same as the ones from original product version'
      );
    }
    return Promise.resolve();
  }

  const effectiveDateControl = (
    <Form.Item
      name="effectiveDate"
      label="Effective Date"
      dependencies={['stateCode']}
      rules={[{required: true}, {validator: effectiveDateStateValidator}]}
    >
      <DatePicker format={DateDataFormats} />
    </Form.Item>
  );

  const stateCodeControl = (
    <Form.Item
      name="stateCode"
      label="State"
      dependencies={['effectiveDate']}
      rules={[{required: true}, {validator: effectiveDateStateValidator}]}
    >
      <Select
        showSearch
        optionFilterProp="label"
        placeholder="select a State"
        options={StateCodeOptions}
      />
    </Form.Item>
  );

  const errorModal = errorModalState.open ? (
    <ErrorModal
      open={errorModalState.open}
      errorMessage={errorModalState.message}
      onOk={() => setErrorModalState({open: false, message: ''})}
    />
  ) : (
    <></>
  );

  return (
    <Form
      name="copy_product_version"
      layout="vertical"
      size="large"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      labelWrap
      style={{width: '100%'}}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      initialValues={{
        effectiveDate: dayjs(),
        stateCode: props.productVersionStateCode,
      }}
    >
      {effectiveDateControl}
      {stateCodeControl}
      <Form.Item
        name="description"
        label="Description"
        shouldUpdate
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Flex style={{width: '100%'}} justify="end" className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" htmlType="submit">
          Create
        </Button>
      </Flex>
      {errorModal}
    </Form>
  );
}
