import {SERVER_BASE_URL} from '../../common/service/Constants';
import {
  ConvertToQueryParams,
  CreateStandardHeaders,
  HandleFetchBlob,
  HandleFetchJson,
  HandleFetchVoid,
} from '../../common/service/Service';
import {ProductVersionSummary} from '../../product/model/Product';
import {
  Study,
  StudyInfo,
  StudyInfoQuery,
  StudyProductVersion,
  StudyTableComparison,
  StudyTableComparisonTable,
  StudyTableComparisonTableSummaryInfo,
} from '../model/ComparativeAnalysis';

const SERVER_URL = SERVER_BASE_URL;

const QUERY_STUDY_URL = `${SERVER_URL}/admin/comparative-analysis/study/query`;
const GET_STUDY_URL = (id: string) =>
  `${SERVER_URL}/admin/comparative-analysis/study/get/${id}`;
const SAVE_STUDY_URL = `${SERVER_URL}/admin/comparative-analysis/study/update`;
const SAVE_STUDY_MERGE_PRODUCT_VERSIONS_URL = `${SERVER_URL}/admin/comparative-analysis/study/updatemergeproductversion/update`;
const DELETE_STUDY_URL = (id: string) =>
  `${SERVER_URL}/admin/comparative-analysis/study/delete/${id}`;

const QUERY_STUDY_PRODUCT_VERSION_URL = (studyId: string) =>
  `${SERVER_URL}/admin/comparative-analysis/studyproductversion/query?studyId=${encodeURI(studyId)}`;
const GET_STUDY_PRODUCT_VERSION_URL = (id: string) =>
  `${SERVER_URL}/admin/comparative-analysis/studyproductversion/get/${id}`;
const SAVE_STUDY_PRODUCT_VERSION_URL = `${SERVER_URL}/admin/comparative-analysis/studyproductversion/update`;
const DELETE_STUDY_PRODUCT_VERSION_URL = (id: string) =>
  `${SERVER_URL}/admin/comparative-analysis/studyproductversion/delete/${id}`;

const QUERY_STUDY_TABLE_COMPARISON_URL = (studyId: string) =>
  `${SERVER_URL}/admin/comparative-analysis/studytablecomparison/query?studyId=${encodeURI(studyId)}`;
const GET_STUDY_TABLE_COMPARISON_URL = (id: string) =>
  `${SERVER_URL}/admin/comparative-analysis/studytablecomparison/get/${id}`;
const SAVE_STUDY_TABLE_COMPARISON_URL = `${SERVER_URL}/admin/comparative-analysis/studytablecomparison/update`;
const SAVE_STUDY_TABLE_COMPARISON_MERGE_TABLES_URL = `${SERVER_URL}/admin/comparative-analysis/studytablecomparison/updatemergetable`;
const DELETE_STUDY_TABLE_COMPARISON_URL = (id: string) =>
  `${SERVER_URL}/admin/comparative-analysis/studytablecomparison/delete/${id}`;
const EXPORT_STUDY_TABLE_COMPARISON_URL = (id: string) =>
  `${SERVER_URL}/admin/comparative-analysis/studytablecomparison/export/${encodeURI(id)}`;

const QUERY_STUDY_TABLE_COMPARISON_TABLE_URL = (tableComparisonId: string) =>
  `${SERVER_URL}/admin/comparative-analysis/studytablecomparisontable/query?tableComparisonId=${encodeURI(tableComparisonId)}`;
const QUERY_STUDY_TABLE_COMPARISON_TABLE_SUMMARY_URL = (
  tableComparisonId: string
) =>
  `${SERVER_URL}/admin/comparative-analysis/studytablecomparisontable/querysummary?tableComparisonId=${encodeURI(tableComparisonId)}`;
const GET_STUDY_TABLE_COMPARISON_TABLE_URL = (id: string) =>
  `${SERVER_URL}/admin/comparative-analysis/studytablecomparisontable/get/${id}`;
const SAVE_STUDY_TABLE_COMPARISON_TABLE_URL = `${SERVER_URL}/admin/comparative-analysis/studytablecomparisontable/update`;
const DELETE_STUDY_TABLE_COMPARISON_TABLE_URL = (id: string) =>
  `${SERVER_URL}/admin/comparative-analysis/studytablecomparisontable/delete/${id}`;

const QUERY_ALL_STUDY_INFO = (query: StudyInfoQuery) =>
  `${SERVER_URL}/admin/comparative-analysis/studyinfo/query${ConvertToQueryParams(query)}`;
const QUERY_STUDY_INFO_BY_ID = (id: string) =>
  `${SERVER_URL}/admin/comparative-analysis/studyinfo/get/${id}`;

const QUERY_PRODUCT_VERSION_SUMMARIES_BY_STUDY_ID = (studyId: string) =>
  `${SERVER_URL}/admin/comparative-analysis/productversionbystudy/get?studyId=${encodeURI(studyId)}`;

export interface AnalysisService {
  GetStudies(): Promise<Study[]>;
  GetStudy(id: string): Promise<Study>;
  QueryStudyInfo(query: StudyInfoQuery): Promise<StudyInfo[]>;
  GetStudyInfoById(id: string): Promise<StudyInfo>;
  UpdateStudy(study: Study): Promise<Study>;
  UpdateStudyMergeProductVersion(
    study: Study,
    productVersiondIds: string[]
  ): Promise<Study>;
  DeleteStudy(id: string): Promise<void>;

  GetStudyProductVersionsByStudyId(
    studyId: string
  ): Promise<StudyProductVersion[]>;
  GetStudyProductVersion(id: string): Promise<StudyProductVersion>;
  UpdateStudyProductVersion(
    studyProductVersion: StudyProductVersion
  ): Promise<StudyProductVersion>;
  DeleteStudyProductVersion(id: string): Promise<void>;

  GetStudyTableComparisonsByStudyId(
    studyId: string
  ): Promise<StudyTableComparison[]>;
  GetStudyTableComparison(id: string): Promise<StudyTableComparison>;
  UpdateStudyTableComparison(
    studyTableComparison: StudyTableComparison
  ): Promise<StudyTableComparison>;
  UpdateStudyTableComparisonMergeTables(
    studyTableComparison: StudyTableComparison,
    tableIds: string[]
  ): Promise<StudyTableComparison>;
  DeleteStudyTableComparison(id: string): Promise<void>;
  ExportStudyTableComparison(id: string): Promise<Blob>;

  GetStudyTableComparisonTableSummariesByTableComparisonId(
    tableComparisonId: string
  ): Promise<StudyTableComparisonTableSummaryInfo[]>;
  GetStudyTableComparisonTableByTableComparisonId(
    tableComparisonId: string
  ): Promise<StudyTableComparisonTable[]>;
  GetStudyTableComparisonTable(id: string): Promise<StudyTableComparisonTable>;
  UpdateStudyTableComparisonTable(
    studyTableComparisonTable: StudyTableComparisonTable
  ): Promise<StudyTableComparisonTable>;
  DeleteStudyTableComparisonTable(id: string): Promise<void>;

  GetProductVersionSummariesByStudyId(
    studyId: string
  ): Promise<ProductVersionSummary[]>;
}

export default function NewAnalysisService(authToken: string): AnalysisService {
  function createHeaders() {
    return CreateStandardHeaders(authToken);
  }
  async function GetStudies(): Promise<Study[]> {
    return HandleFetchJson(
      fetch(QUERY_STUDY_URL, {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }
  async function GetStudy(id: string): Promise<Study> {
    return HandleFetchJson(
      fetch(GET_STUDY_URL(id), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function QueryStudyInfo(query: StudyInfoQuery): Promise<StudyInfo[]> {
    return HandleFetchJson(
      fetch(QUERY_ALL_STUDY_INFO(query), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function GetStudyInfoById(id: string): Promise<StudyInfo> {
    return HandleFetchJson(
      fetch(QUERY_STUDY_INFO_BY_ID(id), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function UpdateStudy(study: Study): Promise<Study> {
    return HandleFetchJson(
      fetch(SAVE_STUDY_URL, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify(study),
      })
    );
  }

  async function UpdateStudyMergeProductVersion(
    study: Study,
    productVersionIds: string[]
  ): Promise<Study> {
    return HandleFetchJson(
      fetch(SAVE_STUDY_MERGE_PRODUCT_VERSIONS_URL, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify({
          study: study,
          productVersionIds: productVersionIds,
        }),
      })
    );
  }

  async function DeleteStudy(id: string): Promise<void> {
    return HandleFetchVoid(
      fetch(DELETE_STUDY_URL(id), {
        method: 'DELETE',
        headers: createHeaders(),
      })
    );
  }

  async function GetStudyProductVersionsByStudyId(
    studyId: string
  ): Promise<StudyProductVersion[]> {
    return HandleFetchJson(
      fetch(QUERY_STUDY_PRODUCT_VERSION_URL(studyId), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function GetStudyProductVersion(
    id: string
  ): Promise<StudyProductVersion> {
    return HandleFetchJson(
      fetch(GET_STUDY_PRODUCT_VERSION_URL(id), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function UpdateStudyProductVersion(
    studyProductVersion: StudyProductVersion
  ): Promise<StudyProductVersion> {
    return HandleFetchJson(
      fetch(SAVE_STUDY_PRODUCT_VERSION_URL, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify(studyProductVersion),
      })
    );
  }

  async function DeleteStudyProductVersion(id: string): Promise<void> {
    return HandleFetchVoid(
      fetch(DELETE_STUDY_PRODUCT_VERSION_URL(id), {
        method: 'DELETE',
        headers: createHeaders(),
      })
    );
  }

  async function GetStudyTableComparisonsByStudyId(
    studyId: string
  ): Promise<StudyTableComparison[]> {
    return HandleFetchJson(
      fetch(QUERY_STUDY_TABLE_COMPARISON_URL(studyId), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function GetStudyTableComparison(
    id: string
  ): Promise<StudyTableComparison> {
    return HandleFetchJson(
      fetch(GET_STUDY_TABLE_COMPARISON_URL(id), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function UpdateStudyTableComparison(
    studyTableComparison: StudyTableComparison
  ): Promise<StudyTableComparison> {
    return HandleFetchJson(
      fetch(SAVE_STUDY_TABLE_COMPARISON_URL, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify(studyTableComparison),
      })
    );
  }

  async function UpdateStudyTableComparisonMergeTables(
    studyTableComparison: StudyTableComparison,
    tableIds: string[]
  ): Promise<StudyTableComparison> {
    return HandleFetchJson(
      fetch(SAVE_STUDY_TABLE_COMPARISON_MERGE_TABLES_URL, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify({
          tableComparison: studyTableComparison,
          tableIds: tableIds,
        }),
      })
    );
  }

  async function DeleteStudyTableComparison(id: string): Promise<void> {
    return HandleFetchVoid(
      fetch(DELETE_STUDY_TABLE_COMPARISON_URL(id), {
        method: 'DELETE',
        headers: createHeaders(),
      })
    );
  }

  async function ExportStudyTableComparison(id: string): Promise<Blob> {
    return HandleFetchBlob(
      fetch(EXPORT_STUDY_TABLE_COMPARISON_URL(id), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function GetStudyTableComparisonTableSummariesByTableComparisonId(
    tableComparisonId: string
  ): Promise<StudyTableComparisonTableSummaryInfo[]> {
    return HandleFetchJson(
      fetch(QUERY_STUDY_TABLE_COMPARISON_TABLE_SUMMARY_URL(tableComparisonId), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function GetStudyTableComparisonTableByTableComparisonId(
    tableComparisonId: string
  ): Promise<StudyTableComparisonTable[]> {
    return HandleFetchJson(
      fetch(QUERY_STUDY_TABLE_COMPARISON_TABLE_URL(tableComparisonId), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function GetStudyTableComparisonTable(
    id: string
  ): Promise<StudyTableComparisonTable> {
    return HandleFetchJson(
      fetch(GET_STUDY_TABLE_COMPARISON_TABLE_URL(id), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function UpdateStudyTableComparisonTable(
    studyTableComparisonTable: StudyTableComparisonTable
  ): Promise<StudyTableComparisonTable> {
    return HandleFetchJson(
      fetch(SAVE_STUDY_TABLE_COMPARISON_TABLE_URL, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify(studyTableComparisonTable),
      })
    );
  }

  async function DeleteStudyTableComparisonTable(id: string): Promise<void> {
    return HandleFetchVoid(
      fetch(DELETE_STUDY_TABLE_COMPARISON_TABLE_URL(id), {
        method: 'DELETE',
        headers: createHeaders(),
      })
    );
  }

  async function GetProductVersionSummariesByStudyId(
    studyId: string
  ): Promise<ProductVersionSummary[]> {
    return HandleFetchJson(
      fetch(QUERY_PRODUCT_VERSION_SUMMARIES_BY_STUDY_ID(studyId), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  return {
    GetStudies,
    GetStudy,
    QueryStudyInfo,
    GetStudyInfoById,
    UpdateStudy,
    UpdateStudyMergeProductVersion,
    DeleteStudy,

    GetStudyProductVersionsByStudyId,
    GetStudyProductVersion,
    UpdateStudyProductVersion,
    DeleteStudyProductVersion,

    GetStudyTableComparisonsByStudyId,
    GetStudyTableComparison,
    UpdateStudyTableComparison,
    UpdateStudyTableComparisonMergeTables,
    DeleteStudyTableComparison,
    ExportStudyTableComparison,

    GetStudyTableComparisonTableByTableComparisonId,
    GetStudyTableComparisonTableSummariesByTableComparisonId,
    GetStudyTableComparisonTable,
    UpdateStudyTableComparisonTable,
    DeleteStudyTableComparisonTable,

    GetProductVersionSummariesByStudyId,
  };
}
