import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import {Flex, Input} from 'antd';
import React, {useEffect, useState} from 'react';
import {DataGridSettings} from '../../../common/control/Common';
import {IdAndName, TableDiff} from '../../../product/model/Product';
import {ProductService} from '../../../product/service/ProductService';

export interface ProductVersionTableDiffListProps {
  productVersionIdOne: string;
  productVersionIdTwo: string;
  productService: ProductService;
  selectedTableName: string | undefined;
  setSelectedTableName: (id: string | undefined) => void;
}

export function ProductVersionTableDiffList(
  props: ProductVersionTableDiffListProps
) {
  // used to trigger a refresh on the UI on change
  const [tableDiff, setTableDiff] = useState<TableDiff>();
  const [tableNames, setTableNames] = useState<string[]>([]);
  const [rows, setRows] = useState<IdAndName[]>([]);
  const [displayTableNames, setDisplayTableNames] = useState<string[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');

  useEffect(() => {
    const eff = async () => {
      const tableDiff = await props.productService.GetProductVersionTableDiff(
        props.productVersionIdOne,
        props.productVersionIdTwo
      );
      setTableDiff(tableDiff);

      const tableNames = [
        ...tableDiff.addedTableNames,
        ...tableDiff.deletedTableNames,
        ...tableDiff.modifiedTableNames,
      ];
      setTableNames(tableNames);
      const displayTableNames = tableNames.filter(t =>
        t.toLowerCase().includes(filterValue.toLowerCase())
      );
      setDisplayTableNames(displayTableNames);
      const rows: IdAndName[] = [];
      for (let i = 0; i < displayTableNames.length; i++) {
        rows.push({id: displayTableNames[i], name: displayTableNames[i]});
      }
      setRows(rows);

      if (!props.selectedTableName && tableNames.length > 0) {
        props.setSelectedTableName(tableNames[0]);
      } else {
        props.setSelectedTableName(undefined);
      }
    };
    eff();
  }, [props.productVersionIdOne, props.productVersionIdTwo]);

  useEffect(() => {
    if (filterValue === '') {
      setDisplayTableNames(tableNames);
      return;
    }
    const displayTableNames = tableNames.filter(t =>
      t.toLowerCase().includes(filterValue.toLowerCase())
    );
    setDisplayTableNames(displayTableNames);
    const rows: IdAndName[] = [];
    for (let i = 0; i < displayTableNames.length; i++) {
      rows.push({id: displayTableNames[i], name: displayTableNames[i]});
    }
    setRows(rows);
    props.setSelectedTableName(
      displayTableNames.length > 0 ? displayTableNames[0] : undefined
    );
  }, [filterValue, tableNames]);

  const colDefs: GridColDef<IdAndName>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 200,
    },
  ];

  function onFilterNameChanged(value: string) {
    setFilterValue(value);
  }

  function onTreeControlSelectionChanged(selection: GridRowSelectionModel) {
    props.setSelectedTableName(
      selection.length > 0 ? (selection[0] as string) : undefined
    );
  }

  return (
    <>
      <Flex vertical gap={15} style={{width: '100%'}}>
        <Input
          placeholder="Enter name search"
          value={filterValue}
          onChange={e => onFilterNameChanged(e.target.value)}
        />
        <div
          className={'ag-theme-quartz'}
          style={{height: '100%', width: '100%'}}
        >
          <DataGrid
            {...DataGridSettings}
            slots={{columnHeaders: () => null}}
            columnHeaderHeight={0}
            columns={colDefs}
            filterMode="client"
            rowHeight={42}
            onRowSelectionModelChange={onTreeControlSelectionChanged}
            rowSelectionModel={
              props.selectedTableName ? [props.selectedTableName] : []
            }
            sx={{
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#F5F5F5',
              },
              '& .MuiDataGrid-cell': {
                border: 0,
              },
              '&.MuiDataGrid-root': {
                borderRadius: '8px',
                overflow: 'hidden',
                borderColor: '#CCCCCC',
              },
            }}
            rows={rows}
            pageSizeOptions={[17]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 17,
                },
              },
            }}
          />
        </div>
      </Flex>
    </>
  );
}
