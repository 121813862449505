import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  GetRowIdParams,
  ICellRendererParams,
  ModuleRegistry,
  SelectionChangedEvent,
} from '@ag-grid-community/core';
import {AgGridReact} from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import Icon from '@mui/material/Icon';
import * as kot from 'adaptify-multi-module-rating-admin-model';
import {
  Button,
  Col,
  Dropdown,
  Flex,
  Form,
  Input,
  MenuProps,
  Row,
  Space,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {ButtonSettings} from '../../../common/control/Common';
import {ConfirmationModal} from '../../../common/control/ConfirmationModal';
import {DraggableModal} from '../../../common/control/DraggableModal';
import {ErrorModal} from '../../../common/control/ErrorModal';
import {HTTP_BAD_REQUEST} from '../../../common/service/Constants';
import {CreateAuthHeader, HttpError} from '../../../common/service/Service';
import {ProductService} from '../../../product/service/ProductService';
import {
  GetImportTableAsCsvUrl,
  RatingService,
} from '../../service/RatingService';
import {DirtyEditorConfirmationModal} from '../DirtyEditorConfirmationModal';
import {CopyTableModal} from './CopyTableModal';
import {CreateTableForm} from './CreateTableForm';
import {
  CustomTableHeaderWithMenu,
  CustomTableHeaderWithMenuProps,
  SwapColumnLocation,
} from './CustomTableHeaderWithMenu';
import {EditColumnForm} from './EditColumnForm';
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import Column = kot.com.adaptify.rating.admin.model.table.Column;
import ColumnType = kot.com.adaptify.rating.admin.model.table.ColumnType;
import ColumnEvaluationType = kot.com.adaptify.rating.admin.model.table.ColumnEvaluationType;
import PrimitiveDataType = kot.com.adaptify.rating.admin.model.type.PrimitiveDataType;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import ScopedVariable = kot.com.adaptify.rating.admin.model.calculation.context.ScopedVariable;

ModuleRegistry.registerModules([ClientSideRowModelModule]);

export interface ProductVersionTableDetailsProps {
  productVersionId: string;
  productVersionTable?: Table;
  updateProductVersionTable: (table: Table) => void;
  saveTable: (table: Table) => Promise<Table>;
  deleteTable?: (id: string) => Promise<void>;
  isDirty: boolean;
  lobHierarchy: LineOfBusinessHierarchy | undefined;
  productService: ProductService;
  ratingService: RatingService;
  authToken: string;
  onChanged: () => void;
  canUndo: boolean;
  undo: () => void;
  canRedo: boolean;
  redo: () => void;
  hideCreateAndDeleteButtons?: boolean;

  modifyCount: number;
  readOnly: boolean;
  globalVariables: ScopedVariable[];
  tableHeight?: string;
}

interface RowData {
  id: string;
  data: String[];
}

interface ColumnEditState {
  isEditing: boolean;
  isNewColumn: boolean;
  column?: Column;
  columnIndex: number;
}

interface TableCreateState {
  open: boolean;
  productVesionTable?: Table;
}

interface ErrorModalState {
  open: boolean;
  message: string;
}

export function ProductVersionTableDetails(
  props: ProductVersionTableDetailsProps
) {
  const [selectedRow, setSelectedRow] = React.useState<number>();
  const [colDefs, setColDefs] = useState([] as ColDef<RowData>[]);
  const [colWidths, setColWidths] = useState<Map<string, number>>(new Map());

  const [rows, setRows] = useState([] as RowData[]);
  // local changes don't refresh from server
  const [columnEditState, setColumnEditState] = useState<ColumnEditState>({
    isEditing: false,
    isNewColumn: false,
    columnIndex: -1,
  });
  const [tableCreateState, setTableCreateState] = useState<TableCreateState>({
    open: false,
  });
  // table name needs to be separate so we can control commits of the data, otherwise every letter we type will be in the undo stack
  const [tableName, setTableName] = useState('');
  const [errorModalState, setErrorModalState] = useState<ErrorModalState>({
    open: false,
    message: '',
  });

  const [deleteTableConfirmOpen, setDeleteTableConfirmOpen] =
    React.useState(false);
  const [exportTableConfirmOpen, setExportTableConfirmOpen] =
    React.useState(false);

  const [copyTableModalOpen, setCopyTableModalOpen] = useState(false);

  useEffect(() => {
    // reload columns and rows when table changes
    setTableName(props.productVersionTable?.name || '');
    setColDefs(getColumns());
    setRows(getTableRows());
  }, [props.productVersionTable]);

  const [editColumnForm] = Form.useForm();

  const columns = getColumns();
  props.productService.GetLobHierarchyForProductVersionId;

  function moveRowUpOrDown(rowIndex: number, isUp: boolean) {
    if (!props.productVersionTable || rowIndex === undefined) {
      return;
    }

    const updated = structuredClone(props.productVersionTable);
    const newRow = isUp ? rowIndex - 1 : rowIndex + 1;

    for (let i = 0; i < columns.length; i++) {
      const temp = updated.rows[rowIndex][i];
      updated.rows[rowIndex][i] = updated.rows[newRow][i];
      updated.rows[newRow][i] = temp;
    }
    setRows(getTableRows());
    props.updateProductVersionTable(updated);
  }

  function moveRowUp(rowIndex: number) {
    moveRowUpOrDown(rowIndex, true);
  }

  function moveRowDown(rowIndex: number) {
    moveRowUpOrDown(rowIndex, false);
  }

  function addRow(rowIndex: number) {
    if (!props.productVersionTable) {
      return;
    }
    const newRow: string[] = [];
    for (let i = 0; i < columns.length; i++) {
      newRow.push('');
    }
    const updated = structuredClone(props.productVersionTable);
    updated.rows.splice(rowIndex, 0, newRow);
    props.updateProductVersionTable(updated);
  }

  function deleteRow(rowIndex: number) {
    if (!props.productVersionTable || rowIndex === undefined) {
      return;
    }
    const updated = structuredClone(props.productVersionTable);
    updated.rows.splice(rowIndex, 1);
    props.updateProductVersionTable(updated);
  }

  async function saveTable() {
    if (props.productVersionTable) {
      await props.saveTable(props.productVersionTable);
    }
  }

  async function deleteTable() {
    if (props.productVersionTable && props.deleteTable) {
      const a = await props
        .deleteTable(props.productVersionTable.id)
        .catch((e: HttpError) => {
          if (e.details && e.statusCode === HTTP_BAD_REQUEST) {
            setErrorModalState({
              open: true,
              message: e.details.message,
            });
            return Promise.resolve();
          }
        });
    }
  }

  function generateRowIdFromIndex(index: number) {
    return props.productVersionTable?.id + '_' + index;
  }

  function getIndexFromRowId(rowId: string) {
    const idSections = rowId.split('_');
    return parseInt(idSections[idSections.length - 1]);
  }

  async function downloadTableAsCsv() {
    // to ensure JWT token is passed, make an API call to get the spreadsheet data
    // then hook the local data up to a download link
    if (!props.productVersionTable) {
      return;
    }
    if (props.isDirty) {
      // if there are pending changes, save them before export
      await props.saveTable(props.productVersionTable);
    }
    const contents = await props.ratingService.ExportTableAsCsv(
      props.productVersionTable?.id || ''
    );
    const contentsAsBlob = new Blob([contents], {type: 'text/csv'});
    const objectUrl = window.URL.createObjectURL(contentsAsBlob);
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    anchor.href = objectUrl;
    anchor.download = props.productVersionTable?.name + '.csv';
    anchor.click();

    window.URL.revokeObjectURL(objectUrl);
    anchor.remove();
  }

  function getColumns(): ColDef<RowData>[] {
    if (!props.productVersionTable) {
      return [];
    }
    if (
      !props.productVersionTable?.columns ||
      props.productVersionTable.columns.length === 0
    ) {
      if (props.readOnly) {
        return [];
      }
      // create a dummy column so that the user can add a new column
      return [
        {
          width: 150,
          colId: 'dummy',
          headerName: 'Add a new column',
          valueGetter: 'Add a new column',
          sortable: false,
          headerComponent: CustomTableHeaderWithMenu,
          headerComponentParams: {
            table: props.productVersionTable,
            columnIndex: -1,
            onColumnsChange: props.updateProductVersionTable,
            onAddColumn: openAddColumn,
            onEditColumn: openEditColumn,
          } as CustomTableHeaderWithMenuProps,
        },
      ];
    }

    const gridColumns: ColDef<RowData>[] =
      props.productVersionTable.columns.map((col, index) => {
        const id = generateRowIdFromIndex(index);
        const computedDefaultColumnWidth = (col.name?.length ?? 10) * 9;
        const defaultColumnWidth =
          computedDefaultColumnWidth > 200 ? computedDefaultColumnWidth : 150;
        return {
          colId: id,
          headerName: col.name,
          width: colWidths[id] || defaultColumnWidth,
          editable:
            props.productVersionTable?.columns &&
            props.productVersionTable?.columns.length > 0 &&
            !props.readOnly,

          sortable: false,
          headerComponent: CustomTableHeaderWithMenu,
          headerComponentParams: {
            table: props.productVersionTable,
            columnIndex: index,
            onColumnsChange: props.updateProductVersionTable,
            onAddColumn: openAddColumn,
            onEditColumn: openEditColumn,
            readOnly: props.readOnly,
          } as CustomTableHeaderWithMenuProps,
          valueGetter: row => {
            return row.data?.data[index] || '';
          },
          valueSetter: row => {
            while (row.data.data.length <= index) {
              row.data.data.push('');
            }

            const updated = structuredClone(props.productVersionTable);
            if (!updated) {
              return;
            }

            const rowIndex = getIndexFromRowId(row.data.id);

            while (updated.rows.length <= rowIndex) {
              updated.rows.push([]);
            }
            if (row.data.data[index] !== row.newValue) {
              updated.rows[rowIndex][index] = row.newValue;
              props.updateProductVersionTable(updated);
            }
            return true;
          },
        } as ColDef<RowData>;
      });

    const actionMenu: (
      params: ICellRendererParams<RowData>
    ) => MenuProps['items'] = (params: ICellRendererParams<RowData>) => {
      const rowIndex = params.data?.id ? getIndexFromRowId(params.data.id) : -1;
      return [
        {
          label: (
            <a type="link" onClick={() => moveRowUp(rowIndex)}>
              Move Row Up
            </a>
          ),
          key: '1',
          disabled: rowIndex === undefined || rowIndex <= 0,
        },
        {
          label: (
            <a type="link" onClick={() => moveRowDown(rowIndex)}>
              Move Row Down
            </a>
          ),
          key: '2',
          disabled: rowIndex === undefined || rowIndex >= rows.length,
        },
        {
          label: (
            <a type="link" onClick={() => addRow(rowIndex)}>
              Add Row
            </a>
          ),
          key: '3',
        },
        {
          label: (
            <a type="link" onClick={() => deleteRow(rowIndex)}>
              Delete Row
            </a>
          ),
          key: '4',
          disabled: rowIndex === undefined || rowIndex < 0,
        },
      ];
    };

    const actionColumn: ColDef<RowData> = {
      colId: 'actions',
      headerName: '',
      editable: false,
      width: 80,
      sortable: false,
      headerComponent: CustomTableHeaderWithMenu,
      headerComponentParams: {
        table: props.productVersionTable,
        columnIndex: -1,
        onColumnsChange: props.updateProductVersionTable,
        onAddColumn: openAddColumn,
        onEditColumn: openEditColumn,
      } as CustomTableHeaderWithMenuProps,
      cellRenderer: rowData => (
        <Dropdown trigger={['click']} menu={{items: actionMenu(rowData)}}>
          <Button {...ButtonSettings} onClick={e => e.preventDefault()}>
            ...
          </Button>
        </Dropdown>
      ),
    };
    if (props.readOnly) {
      return gridColumns;
    } else {
      return [...gridColumns, actionColumn];
    }
  }

  function getTableRows(): RowData[] {
    if (
      !props.productVersionTable ||
      !props.productVersionTable.columns ||
      props.productVersionTable.columns.length === 0
    ) {
      return [{id: '-1', data: ['Add a new column']}];
    }
    if (!props.productVersionTable.rows) {
      props.productVersionTable.rows = [];
    }

    const tableRows = props.productVersionTable.rows.map((row, index) => {
      return {
        id: generateRowIdFromIndex(index),
        data: row.map(data => data || ''),
      };
    });
    // add a dummy row for editing convenience
    if (
      props.productVersionTable.rows.length === 0 ||
      props.productVersionTable.rows[
        props.productVersionTable.rows.length - 1
      ].filter(data => data !== '').length > 0
    ) {
      tableRows.push({
        id: generateRowIdFromIndex(props.productVersionTable.rows.length),
        data: props.productVersionTable.columns.map(() => ''),
      });
    }
    return tableRows;
  }

  const getRowId = useCallback(
    (params: GetRowIdParams<RowData>) => params.data.id,
    []
  );

  function onSelectionChanged(selection: SelectionChangedEvent<RowData>) {
    const selectedRows = selection.api.getSelectedRows();
    if (selectedRows.length === 0) {
      setSelectedRow(undefined);
    } else {
      const index = getIndexFromRowId(selectedRows[0].id);
      setSelectedRow(index);
    }
  }

  function openAddColumn(columnIndex: number) {
    const column = {
      name: '',
      dataType: PrimitiveDataType.String.name,
      columnType: ColumnType.Input.name,
      evaluationType: ColumnEvaluationType.Equals.value,
    } as Column;
    setColumnEditState({
      isEditing: true,
      isNewColumn: true,
      column: column,
      columnIndex: columnIndex ?? -1,
    });
    editColumnForm.setFieldsValue(column);
  }

  function openEditColumn(column: Column, columnIndex: number) {
    const newColumn = {...column};
    setColumnEditState({
      isEditing: true,
      isNewColumn: false,
      column: column,
      columnIndex: columnIndex,
    });
    editColumnForm.setFieldsValue(newColumn);
  }

  function onSaveColumn(column: Column, columnIndex: number) {
    if (!props.productVersionTable) {
      return;
    }
    if (!columnEditState.isNewColumn) {
      const currentColumn = props.productVersionTable.columns[columnIndex];
      if (
        currentColumn.columnType === column.columnType &&
        currentColumn.evaluationType === column.evaluationType &&
        currentColumn.name === column.name &&
        currentColumn.path === column.path
      ) {
        // cancel the edit if there are no changes
        setColumnEditState({
          isEditing: false,
          isNewColumn: false,
          column: {},
          columnIndex: -1,
        });
        return;
      }
    }
    if (columnIndex === -1) {
      // if column index isn't in the table add to the end
      columnIndex = props.productVersionTable.columns.length - 1;
    }

    const updated = structuredClone(props.productVersionTable);
    if (columnEditState.isNewColumn) {
      if (!updated.columns) {
        updated.columns = [];
      }
      if (updated.columns.length === 0) {
        updated.columns.push(column);
      } else {
        updated.columns.splice(columnIndex + 1, 0, column);
      }
    } else {
      updated.columns[columnIndex] = column;
    }

    if (!updated.rows) {
      updated.rows = [];
    }
    if (columnEditState.isNewColumn) {
      // add an empty value for each row in the column location
      updated.rows.forEach(row => {
        row.splice(columnIndex + 1, 0, '');
      });
    }

    setColumnEditState({
      isEditing: false,
      isNewColumn: false,
      column: {},
      columnIndex: columnIndex,
    });
    props.updateProductVersionTable(updated);
  }

  function onAddEditColumnModalCancel() {
    setColumnEditState({
      isEditing: false,
      isNewColumn: false,
      column: {},
      columnIndex: -1,
    });
  }

  function openCreateTableDialog() {
    setTableCreateState({
      open: true,
    });
  }

  function openCopyTableDialog() {
    setTableCreateState({
      open: true,
      productVesionTable: props.productVersionTable,
    });
  }

  async function onSaveNewTable(tableName: string) {
    if (!tableName) {
      return;
    }
    let newTable: Table;
    if (tableCreateState.productVesionTable) {
      newTable = structuredClone(tableCreateState.productVesionTable);
      newTable.id = uuidv4();
      newTable.name = tableName;
      newTable.version = 0;
    } else {
      newTable = {
        id: uuidv4(),
        name: tableName,
        version: 0,
        columns: [],
        rows: [],
      };
    }
    setTableCreateState({
      open: false,
    });
    await props.saveTable(newTable);
  }

  async function onCancelNewTable() {
    setTableCreateState({
      open: false,
    });
  }

  function onTableNameChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setTableName(e.target.value);
  }

  function commitTableNameChanged() {
    if (
      props.productVersionTable &&
      tableName !== props.productVersionTable.name
    ) {
      const updated = structuredClone(props.productVersionTable);
      updated.name = tableName;
      props.updateProductVersionTable(updated);
    }
  }

  function onColumnResized(event: ColumnResizedEvent) {
    const newMap = structuredClone(colWidths);
    if (event.finished && event.column?.getColId()) {
      newMap[event.column.getColId()] = event.column.getActualWidth();
      setColWidths(newMap);
    }
  }

  function onColumnMoved(event: ColumnMovedEvent) {
    if (!event.finished) {
      return;
    }

    const column = event.column;
    if (!column || event.toIndex === undefined) {
      return;
    }

    const oldIndex = event.api
      .getColumns()
      ?.findIndex(c => c.getColId() === column.getColId());

    if (
      oldIndex === undefined ||
      oldIndex === -1 ||
      oldIndex >= columns.length - 1 ||
      !props.productVersionTable
    ) {
      event.api.resetColumnState();
      return;
    }
    // figure out range to move the column to the right place
    let currentIndex = oldIndex;
    let currentTable = props.productVersionTable;
    while (currentIndex !== event.toIndex) {
      const newIndex =
        currentIndex < event.toIndex ? currentIndex + 1 : currentIndex - 1;
      const updated = SwapColumnLocation(currentTable, currentIndex, newIndex);
      if (!updated) {
        // refresh the UI if we couldn't update the table
        event.api.resetColumnState();
        return;
      }
      currentTable = updated;
      currentIndex = newIndex;
    }

    props.updateProductVersionTable(currentTable);
    event.api.resetColumnState();
  }

  async function onBeforeImportCsv(): Promise<void> {
    if (props.productVersionTable && props.isDirty) {
      await props.saveTable(props.productVersionTable);
    }
    return;
  }

  async function onCopyTable(
    productVersionId: string,
    tableName: string,
    newTableName: string
  ) {
    const newTableId = await props.productService
      .CopyProductVersionTable(productVersionId, tableName, false, newTableName)
      .catch((e: HttpError) => {
        setErrorMsg(e.message);
        return undefined;
      });

    setCopyTableModalOpen(false);
    if (newTableId) {
      props.onChanged();
    }
  }

  function onClickCopyTableButton() {
    setCopyTableModalOpen(true);
  }

  const deleteButton =
    props.deleteTable && !props.hideCreateAndDeleteButtons ? (
      <Tooltip placement="top" title={'Delete Table'}>
        <Button
          {...ButtonSettings}
          size="large"
          disabled={!props.productVersionTable || props.readOnly}
          onClick={() => setDeleteTableConfirmOpen(true)}
        >
          <DeleteOutlinedIcon />
        </Button>
      </Tooltip>
    ) : (
      <></>
    );

  // Product requirements are to use the upload style button for export and the download style
  // button for import, this is on purpose
  const exportButton = (
    <Tooltip placement="top" title={'Export'}>
      <Button
        {...ButtonSettings}
        size="large"
        onClick={e => {
          // we need to force a blur so that pending table updates to the focused control get comitted
          setExportTableConfirmOpen(true);
        }}
        rel="noopener noreferrer"
      >
        <FileUploadOutlinedIcon />
      </Button>
    </Tooltip>
  );

  // other components use the paradigm of only including the confirm dialog if it is open, this has a negative
  // interaction with export code, causing a double export, so always generate the DOM for the modal
  const exportTableModal = (
    <DirtyEditorConfirmationModal
      open={exportTableConfirmOpen}
      skipDialog={!props.isDirty}
      onConfirm={async () => {
        setExportTableConfirmOpen(false);
        await downloadTableAsCsv();
      }}
      onCancel={() => setExportTableConfirmOpen(false)}
    />
  );

  const columnModalName = props.readOnly
    ? 'View Column'
    : columnEditState.isNewColumn
      ? 'Add Column'
      : 'Edit Column';

  const editColumnModal = columnEditState.isEditing ? (
    <DraggableModal
      className="adaptify-modal"
      title={columnModalName}
      open={columnEditState.isEditing}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      onCancel={onAddEditColumnModalCancel}
      width={'clamp(300px, 70svw, 800px)'}
      footer={null}
    >
      <EditColumnForm
        column={columnEditState.column}
        lobHierarchy={props.lobHierarchy}
        onSaveColumn={onSaveColumn}
        onCancel={onAddEditColumnModalCancel}
        columnIndex={columnEditState.columnIndex}
        form={editColumnForm}
        globalVariables={props.globalVariables}
        readOnly={props.readOnly}
      />
    </DraggableModal>
  ) : (
    <></>
  );

  const errorModal = errorModalState.open ? (
    <ErrorModal
      open={errorModalState.open}
      errorMessage={errorModalState.message}
      onOk={() => setErrorModalState({open: false, message: ''})}
    />
  ) : (
    <></>
  );

  const deleteTableModal = deleteTableConfirmOpen ? (
    <ConfirmationModal
      open={deleteTableConfirmOpen}
      title="Delete Table"
      message="Are you sure you would like to delete this table?"
      onOk={() => {
        deleteTable();
        setDeleteTableConfirmOpen(false);
      }}
      onCancel={() => setDeleteTableConfirmOpen(false)}
    />
  ) : (
    <></>
  );

  const createButton = !props.hideCreateAndDeleteButtons ? (
    <Tooltip placement="top" title={'Create Table'}>
      <Button
        onClick={openCreateTableDialog}
        {...ButtonSettings}
        size="large"
        disabled={props.productVersionId === '' || props.readOnly}
      >
        <Icon>add_circle</Icon>
      </Button>
    </Tooltip>
  ) : (
    <></>
  );

  const copyTableModal = copyTableModalOpen ? (
    <CopyTableModal
      productVersionId={props.productVersionId}
      sourceTableName={
        props.productVersionTable ? props.productVersionTable.name : ''
      }
      onCopy={onCopyTable}
      onCancel={() => setCopyTableModalOpen(false)}
    />
  ) : (
    <>/</>
  );

  return (
    <>
      <Flex
        vertical
        gap={10}
        style={{paddingBottom: '10px', paddingLeft: '10px'}}
      >
        <Row>
          <Col span={12}>
            <Space direction="horizontal">
              <Typography.Text>Name: </Typography.Text>
              <Input
                value={tableName}
                onChange={onTableNameChanged}
                onBlur={commitTableNameChanged}
                style={{width: '300px'}}
                disabled={!props.productVersionTable || props.readOnly}
              />
            </Space>
          </Col>
          <Col span={12}>
            <Flex align="right" dir="row" justify="end" className="gap-2">
              {createButton}
              <Tooltip placement="top" title={'Copy'}>
                <Button
                  {...ButtonSettings}
                  onClick={onClickCopyTableButton}
                  className="RightButton"
                  disabled={!props.productVersionTable || props.readOnly}
                  size="large"
                >
                  <ContentCopyIcon />
                </Button>
              </Tooltip>
              {exportButton}
              <Upload
                action={GetImportTableAsCsvUrl(
                  props.productVersionTable?.id || ''
                )}
                beforeUpload={onBeforeImportCsv}
                disabled={!props.productVersionTable || props.readOnly}
                accept={'text/csv'}
                showUploadList={false}
                headers={CreateAuthHeader(props.authToken)}
                onChange={e => {
                  if (e.file && e.file.status === 'done') {
                    // refresh the page with the latest from server
                    props.onChanged();
                  }
                }}
              >
                <Tooltip placement="top" title={'Import'}>
                  <Button
                    {...ButtonSettings}
                    disabled={!props.productVersionTable || props.readOnly}
                    size="large"
                  >
                    <FileDownloadOutlinedIcon />
                  </Button>
                </Tooltip>
              </Upload>
              <Tooltip placement="top" title={'Undo'}>
                <Button
                  {...ButtonSettings}
                  onClick={props.undo}
                  className="RightButton"
                  disabled={!props.canUndo || props.readOnly}
                  size="large"
                >
                  <UndoOutlinedIcon />
                </Button>
              </Tooltip>
              <Tooltip placement="top" title={'Redo'}>
                <Button
                  {...ButtonSettings}
                  onClick={props.redo}
                  className="RightButton"
                  disabled={!props.canRedo || props.readOnly}
                  size="large"
                >
                  <RedoOutlinedIcon />
                </Button>
              </Tooltip>
              {deleteButton}
              <Tooltip placement="top" title={'Save'}>
                <Button
                  {...ButtonSettings}
                  onClick={saveTable}
                  className="RightButton"
                  disabled={
                    !props.productVersionTable ||
                    !props.isDirty ||
                    props.readOnly
                  }
                  size="large"
                >
                  <SaveOutlinedIcon />
                </Button>
              </Tooltip>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div
              className={'ag-theme-quartz'}
              style={{
                width: '100%',
                height: props.tableHeight ? props.tableHeight : '800px',
                overflow: 'auto',
              }}
            >
              <AgGridReact
                columnDefs={colDefs}
                rowData={rows}
                getRowId={getRowId}
                rowSelection="single"
                onRowSelected={onSelectionChanged}
                onColumnResized={onColumnResized}
                stopEditingWhenCellsLoseFocus={true}
                readOnlyEdit={props.readOnly}
                onColumnMoved={onColumnMoved}
                pagination={true}
                paginationPageSize={1000}
                paginationPageSizeSelector={false}
              />
            </div>
          </Col>
        </Row>
      </Flex>
      {editColumnModal}
      <DraggableModal
        className="adaptify-modal"
        title="Add Table"
        open={tableCreateState.open}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        closable={false}
        onCancel={onAddEditColumnModalCancel}
        width={'clamp(300px, 70svw, 800px)'}
        footer={null}
      >
        <CreateTableForm
          onCreateTable={onSaveNewTable}
          onCancel={onCancelNewTable}
          modifyCount={props.modifyCount}
        />
      </DraggableModal>
      {errorModal}
      {deleteTableModal}
      {exportTableModal}
      {copyTableModal}
    </>
  );
}
function setErrorMsg(message: string) {
  throw new Error('Function not implemented.');
}
