import {Flex} from 'antd';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {ErrorModal} from '../../common/control/ErrorModal';
import {ChatControl} from '../control/ChatControl';
import {ChatConversationList} from '../control/ChatConversationList';
import {useApi} from '../hook/useApi';
import {useStream} from '../hook/useStream';
import {
  Conversation,
  Message,
  MessageCreatedEvent,
  MessageDeltaEvent,
  MetadataDetails,
  SelectedConversation,
  ThreadCreatedEvent,
} from '../model/Chat';
import {ChatService} from '../service/ChatService';

export interface ChatAssistantPageProps {
  chatService: ChatService;
}

interface ErrorModalState {
  open: boolean;
  message: string;
}

export const ChatAssistantPage: React.FC<ChatAssistantPageProps> = ({
  chatService,
}: ChatAssistantPageProps) => {
  const {conversationId} = useParams<{conversationId: string}>();
  const [selectedConversation, setSelectedConversation] =
    useState<SelectedConversation>(
      conversationId ? {id: conversationId, new: false} : null
    );

  const [errorModalState, setErrorModalState] = useState<ErrorModalState>({
    open: false,
    message: '',
  });
  const errorCallback = (message: string) => {
    setErrorModalState({open: true, message});
  };

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [
    remoteConversations,
    remoteConversationsError,
    remoteConverstionsLoading,
  ] = useApi<Conversation[]>(chatService.GetConversations, [], {
    onError: e => errorCallback(e.message),
    onSuccess: data => setConversations(data),
  });

  async function deleteAllConversations() {
    try {
      await chatService.DeleteConversations();
    } catch (error) {
      errorCallback(error.message);
    }
  }

  const [messages, setMessages] = useState<Message[]>([]);
  const [remoteMessages, remoteMessagesError, remoteMessagesLoading] = useApi<
    Message[]
  >(
    async () => {
      if (selectedConversation && !selectedConversation.new) {
        return await chatService.GetMessagesForConversation(
          selectedConversation.id
        );
      }
      return [];
    },
    [selectedConversation],
    {
      onError: e => errorCallback(e.message),
      onSuccess: data => {
        ingestionMedataLoading;
        if (selectedConversation && !selectedConversation.new) {
          setMessages(data);
        }
      },
    }
  );

  const [ingestionMetadata, ingestionMedataLoading] = useApi<MetadataDetails>(
    async () => {
      return (await chatService.GetDocumentMetdata()) || {companies: {}};
    }
  );

  const conversationState = useStream(chatService.SendMessage, {
    onMessage(event: string, message: any) {
      switch (event) {
        case 'thread.created': {
          const data: ThreadCreatedEvent = message;
          const newConversation: Conversation = {
            id: data.thread_id,
            first_message: data.first_message,
          };
          setConversations(prevConversations => [
            newConversation,
            ...prevConversations,
          ]);
          setSelectedConversation(prevConversation =>
            prevConversation
              ? prevConversation
              : {id: data.thread_id, new: true}
          );
          break;
        }
        case 'thread.message.created': {
          const data: MessageCreatedEvent = message;
          const newMessage: Message = {
            id: data.message_id,
            role: 'assistant',
            content: '',
          };
          if (
            selectedConversation &&
            selectedConversation.id === data.thread_id
          ) {
            setMessages(prevMessages => [...prevMessages, newMessage]);
          }
          break;
        }
        case 'thread.message.delta': {
          const data: MessageDeltaEvent = message;
          setMessages(prevMessages => {
            for (let i = prevMessages.length - 1; i >= 0; i--) {
              if (prevMessages[i].id === data.message_id) {
                const newMessages = [...prevMessages];
                newMessages[i] = {
                  ...newMessages[i],
                  content: newMessages[i].content + data.delta,
                };
                return newMessages;
              }
            }
            return prevMessages;
          });
          break;
        }
        case 'thread.run.completed': {
          conversationState.stopStream();
          break;
        }
        case 'thread.error': {
          errorCallback('An error occurred while processing your message');
          conversationState.stopStream();
          break;
        }
      }
    },
    onError(error: Error) {
      const errorMessage = error.message
        ? error.message
        : 'An error occurred while processing your message';
      errorCallback(errorMessage);
    },
    onClose() {},
  });

  return (
    <>
      <Flex flex={1} gap={15} className="h-full">
        <ChatConversationList
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          loading={remoteConverstionsLoading}
          conversations={conversations}
          setMessages={setMessages}
          conversationState={conversationState}
        />
        <ChatControl
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          loading={remoteMessagesLoading}
          messages={messages}
          setMessages={setMessages}
          conversationState={conversationState}
          ingestionMetadata={
            ingestionMetadata ? ingestionMetadata : {companies: {}}
          }
          remoteConversations={remoteConversations || []}
          deleteAllConversations={deleteAllConversations}
        />
      </Flex>
      {errorModalState.open && (
        <ErrorModal
          open={errorModalState.open}
          errorMessage={errorModalState.message}
          onOk={() => setErrorModalState({open: false, message: ''})}
        />
      )}
    </>
  );
};
