import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Flex} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';
import {useHotkeys} from 'react-hotkeys-hook';
import {LobService} from '../../lob/service/LobService';
import {ProductVersion} from '../../product/model/Product';
import {ProductService} from '../../product/service/ProductService';
import {AuthManager} from '../../user/util/AuthManager';
import {ProductVersionSummaryForm} from '../control/ProductVersionSummaryForm';
import {RatingNavigationBar} from '../control/RatingNavigationBar';
import {ProductVersionTableDetails} from '../control/table/ProductVersionTableDetails';
import {ProductVersionTableList} from '../control/table/ProductVersionTableList';
import {RatingService} from '../service/RatingService';
import {IsProductVersionReadOnly} from './ProductUtils';
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import ScopedVariable = kot.com.adaptify.rating.admin.model.calculation.context.ScopedVariable;
import VariableType = kot.com.adaptify.rating.admin.model.calculation.context.VariableType;
import GlobalVariable = kot.com.adaptify.rating.admin.model.calculation.util.GlobalVariable;
import PrimitiveDataType = kot.com.adaptify.rating.admin.model.type.PrimitiveDataType;

export interface ProductVersionTableControlProps {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  productVersionId: string;
  authToken: string;
  readOnly?: boolean;
  initialTableId?: string;
  onTableSelected?: (tableId: string | undefined) => void;
  authManager: AuthManager;
}

export function ProductVersionTableControl(
  props: ProductVersionTableControlProps
) {
  const [modifyCount, setModifyCount] = useState<number>(0);

  const [selectedTableId, setSelectedTableId] = useState<string | undefined>(
    undefined
  );
  const [lobHierarchy, setLobHierarchy] = useState<
    LineOfBusinessHierarchy | undefined
  >(undefined);

  const [productVersion, setProductVersion] = useState<
    ProductVersion | undefined
  >();

  const [table, setTable] = useState<Table | undefined>();
  // TODO limit the size of the stack so it can't grow indefinitely
  const [undoStack, setUndoStack] = useState<Table[]>([]);
  const [redoStack, setRedoStack] = useState<Table[]>([]);

  const [globalVariables, setGlobalVariables] = useState<GlobalVariable[]>([]);

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionId) {
        setLobHierarchy(undefined);
        return;
      }
      const hierarchyPromise =
        props.productService.GetLobHierarchyForProductVersionId(
          props.productVersionId
        );
      const globalVariablePromise = props.ratingService.GetGlobalVariables(
        props.productVersionId
      );

      const productVersionPromise =
        await props.productService.GetProductVersion(props.productVersionId);

      const [lobs, globals, version] = await Promise.all([
        hierarchyPromise,
        globalVariablePromise,
        productVersionPromise,
      ]);

      setLobHierarchy(lobs);
      setGlobalVariables(globals);
      setProductVersion(version);
    };
    eff();
  }, [props.productVersionId]);

  useEffect(() => {
    const eff = async () => {
      if (!selectedTableId) {
        doSetSelectedTableId(selectedTableId);
        handleNewTableFromServer(undefined);
        return;
      }
      const table =
        await props.productService.GetProductVersionTable(selectedTableId);

      handleNewTableFromServer(table);
    };
    eff();
  }, [selectedTableId, props.initialTableId, modifyCount]);

  const globalScopedVariables = useMemo(() => {
    return globalVariables.map(gv => {
      return {
        name: gv.name,
        displayName: gv.name,
        type: VariableType.Variable.name,
        boundDataType: {
          primitive: gv.primitiveDataType
            ? PrimitiveDataType.valueOf(gv.primitiveDataType)
            : undefined,
          lobItem: undefined,
          isMany: false,
        },
      } as ScopedVariable;
    });
  }, [globalVariables]);

  async function handleNewTableFromServer(table?: Table) {
    setTable(table);
    setUndoStack([]);
    setRedoStack([]);
  }

  function isReadOnly() {
    if (props.readOnly) {
      return true;
    }
    if (!productVersion) {
      return true;
    }

    return IsProductVersionReadOnly(productVersion, props.authManager);
  }

  function onModified() {
    setModifyCount(modifyCount + 1);
  }

  function isDirty() {
    return undoStack.length > 0;
  }

  function onTableLocalChanged(updated: Table) {
    if (table) {
      setUndoStack([...undoStack, table]);
    }
    setTable(updated);
  }

  async function saveTable(tableToSave: Table): Promise<Table> {
    if (!tableToSave) {
      return tableToSave;
    }
    const updated = await props.productService.UpdateProductVersionTable(
      props.productVersionId,
      tableToSave
    );

    // this might be a new table to set the id accordingly
    // this should also reflect on the list UI
    doSetSelectedTableId(updated.id);
    // need to go back to the server after saving because the list might need updating
    // so force reload (esp if it's saving the same table)
    setModifyCount(modifyCount + 1);
    return updated;
  }

  function doSetSelectedTableId(id: string | undefined) {
    setSelectedTableId(id);
    if (props.onTableSelected) {
      props.onTableSelected(id);
    }
  }

  async function deleteTable(tableId: string): Promise<void> {
    await props.productService.DeleteProductVersionTable(tableId);
    doSetSelectedTableId(undefined);
    setModifyCount(modifyCount + 1);
  }

  function canUndo() {
    return undoStack.length > 0;
  }

  function canRedo() {
    return redoStack.length > 0;
  }

  function undo() {
    if (undoStack.length === 0) {
      return;
    }
    const last = undoStack[undoStack.length - 1];
    if (table) {
      setRedoStack([...redoStack, table]);
    }
    setTable(last);
    setUndoStack(undoStack.slice(0, undoStack.length - 1));
  }

  function redo() {
    if (redoStack.length === 0) {
      return;
    }
    const last = redoStack[redoStack.length - 1];
    if (table) {
      setUndoStack([...undoStack, table]);
    }
    setTable(last);
    setRedoStack(redoStack.slice(0, redoStack.length - 1));
  }

  useHotkeys('mod+s', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (table) {
      saveTable(table);
    }
  });

  useHotkeys('mod+z', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (canUndo()) {
      undo();
    }
  });

  // mac os
  useHotkeys('mod+shift+z', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (canRedo()) {
      redo();
    }
  });

  //windows
  useHotkeys('ctrl+y', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (canRedo()) {
      redo();
    }
  });

  return (
    <Flex vertical gap={24}>
      <div style={{height: '100%', width: '100%'}}>
        <ProductVersionSummaryForm
          productService={props.productService}
          productVersionId={props.productVersionId}
          isDirty={isDirty()}
          readOnly={isReadOnly()}
        />
      </div>
      <div style={{minHeight: '46px', width: '100%'}}>
        <RatingNavigationBar
          productVersionId={props.productVersionId}
          isDirty={isDirty()}
          selection="Table"
        />
      </div>
      <Flex>
        <div
          style={{
            width: '300px',
            height: '100%',
            paddingRight: '10px',
            overflow: 'auto',
          }}
        >
          <ProductVersionTableList
            productService={props.productService}
            productVersionId={props.productVersionId}
            setSelectedTableId={doSetSelectedTableId}
            selectedTableId={selectedTableId}
            modifyCount={modifyCount}
            isEditorDirty={isDirty()}
          />
        </div>
        <div style={{flexGrow: 1}}>
          <ProductVersionTableDetails
            productVersionId={props.productVersionId}
            lobHierarchy={lobHierarchy}
            productVersionTable={table}
            productService={props.productService}
            ratingService={props.ratingService}
            authToken={props.authToken}
            onChanged={onModified}
            modifyCount={modifyCount}
            updateProductVersionTable={onTableLocalChanged}
            saveTable={saveTable}
            deleteTable={deleteTable}
            isDirty={isDirty()}
            canUndo={canUndo()}
            canRedo={canRedo()}
            undo={undo}
            redo={redo}
            globalVariables={globalScopedVariables}
            readOnly={isReadOnly()}
          />
        </div>
      </Flex>
    </Flex>
  );
}
