import {Col, Flex, Row, Typography} from 'antd';
import React, {useEffect, useState} from 'react';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import Select, {BaseOptionType} from 'antd/es/select';
import {LobItemType} from '../../../lob/model/LineOfBusiness';
import {IdAndName} from '../../../product/model/Product';
import {ProductService} from '../../../product/service/ProductService';
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import LineOfBusinessHierarchyItem = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchyItem;
import Flow = kot.com.adaptify.rating.admin.model.flow.Flow;

export interface RiskFlowSelectionFormProps {
  productService: ProductService;
  productVersionId: string | undefined;

  selectedRiskId: string | undefined;
  setSelectedRiskId: (riskId: string | undefined) => void;

  selectedRiskFlowId: string | undefined;
  setSelectedRiskFlowId: (id: string) => void;
  modifyCount: number;
}

export function RiskFlowSelectionForm(props: RiskFlowSelectionFormProps) {
  const [lobHierarchy, setLobHierarchy] = useState<
    LineOfBusinessHierarchy | undefined
  >();

  const [riskFlowOptions, setRiskFlowOptions] = useState<BaseOptionType[]>([]);

  const riskOptions = buildRiskSelectOptionsForHierarhcy(lobHierarchy);

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionId) {
        setLobHierarchy(undefined);
        return;
      }

      const loaded =
        await props.productService.GetLobHierarchyForProductVersionId(
          props.productVersionId
        );
      setLobHierarchy(loaded);
      if (!props.selectedRiskId) {
        props.setSelectedRiskId(loaded.risks[0]?.id);
      }
    };
    eff();
  }, [props.productVersionId]);

  useEffect(() => {
    if (!props.productVersionId || !lobHierarchy) {
      return;
    }

    if (!props.setSelectedRiskFlowId) {
      // if there's not selected coverage set the risk id to the first available
      props.setSelectedRiskId(riskOptions[0]?.value);
      return;
    }

    const eff = async () => {
      const loaded =
        await props.productService.GetProductVersionFlowNamesByVersionIdAndLobItemId(
          props.productVersionId ?? '',
          props.selectedRiskId || ''
        );
      loaded.sort((a, b) => a.name.localeCompare(b.name));
      const options = loaded.map((v: IdAndName) => {
        return {
          label: v.name,
          value: v.id,
        };
      });
      setRiskFlowOptions(options);
      const found = props.selectedRiskFlowId
        ? options.find(v => v.value === props.selectedRiskFlowId)
        : undefined;
      if (!found && options.length > 0) {
        props.setSelectedRiskFlowId(options[0]?.value);
      }
    };
    eff();
  }, [
    props.productVersionId,
    lobHierarchy,
    props.selectedRiskId,
    props.modifyCount,
  ]);

  return (
    <Flex vertical>
      <Row gutter={[10, 16]}>
        <Col span={6} className="adaptify-label-column">
          <Typography.Text>Risk Item:</Typography.Text>
        </Col>
        <Col span={6}>
          <Select
            style={{width: '100%'}}
            value={props.selectedRiskId}
            options={riskOptions}
            onChange={props.setSelectedRiskId}
          ></Select>
        </Col>
        <Col span={6} className="adaptify-label-column">
          <Typography.Text>Risk Item Calculation:</Typography.Text>
        </Col>
        <Col span={6}>
          <Select
            style={{width: '100%'}}
            value={props.selectedRiskFlowId}
            options={riskFlowOptions}
            onChange={props.setSelectedRiskFlowId}
          ></Select>
        </Col>
      </Row>
    </Flex>
  );
}

function buildRiskSelectOptionsForHierarhcy(
  lobHierarchy: LineOfBusinessHierarchy | undefined
): BaseOptionType[] {
  if (!lobHierarchy) {
    return [];
  }
  const options = [];
  (lobHierarchy.risks || []).forEach(r => {
    buildRiskSelectOptionsForRiskItem(r, options);
  });
  return options;
}

function buildRiskSelectOptionsForRiskItem(
  item: LineOfBusinessHierarchyItem,
  options: BaseOptionType[]
) {
  if (item.itemType !== LobItemType.Risk) {
    return;
  }

  options.push({
    label: item.name,
    value: item.id,
  });

  item?.children?.forEach(c => {
    buildRiskSelectOptionsForRiskItem(c, options);
  });
}
