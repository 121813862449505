import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import * as kot from 'adaptify-multi-module-rating-admin-model';
import {
  Button,
  Col,
  Dropdown,
  Flex,
  MenuProps,
  Popconfirm,
  Row,
  Space,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../../common/control/Common';
import {DraggableModal} from '../../../common/control/DraggableModal';
import {useErrorHandler} from '../../../common/control/ErrorHandler';
import {NewTenantAwareEntity} from '../../../common/model/Common';
import {TestCaseInfo} from '../../../product/model/Product';
import {ProductService} from '../../../product/service/ProductService';
import {RatingService} from '../../service/RatingService';
import {CreateTestCaseForm} from './CreateTestCaseForm';
import TestCase = kot.com.adaptify.rating.admin.model.testcase.TestCase;

// this control is a little unusual because the add button is in the Card, but the modify buttons are on the grid
// so we are centralizing the mutation behavior on the parent component

export interface TestCaseListProps {
  productVersionId: string | undefined;
  productService: ProductService;
  ratingService: RatingService;
  modifyCount: number;
  setModifyCount: (count: number) => void;
  selectedTestCaseId: string | undefined;
  setSelectedTestCaseId: (id: string | undefined) => void;
  isReadOnly?: boolean;
}

export interface EditState {
  open: boolean;
  testCase: TestCase;
  copyFromId?: string;
}

interface TestCaseWithExecutionStatus extends TestCaseInfo {
  actualPremium: string;
  status: string;
}

export function TestCaseList(props: TestCaseListProps) {
  // used to trigger a refresh on the UI on change
  const [testCases, setTestCases] = useState<TestCaseWithExecutionStatus[]>([]);
  const [editState, setEditState] = useState<EditState>({
    open: false,
    testCase: createTestCase(1),
  });

  // orchestrate running the tests cases one by one from the UI so we need update status as they finish
  const [nextTestCaseToExecuteId, setNextTestCaseToExecuteId] = useState<
    string | undefined
  >(undefined);
  const [isExecutionCanceled, setIsExecutionCanceled] =
    useState<boolean>(false);

  const productService = useErrorHandler(props.productService);

  useEffect(() => {
    const eff = async () => {
      const loadedTestCases =
        await productService.GetProductVersionTestCaseInfosByVersionId(
          props.productVersionId || ''
        );

      const testCasesWithExecutionStatus = loadedTestCases.map(tc => {
        return {
          ...tc,
          actualPremium: '',
          status: '',
        };
      });
      setTestCases(testCasesWithExecutionStatus);
      if (!props.selectedTestCaseId && loadedTestCases.length > 0) {
        props.setSelectedTestCaseId(loadedTestCases[0].id);
      }
    };
    eff();
  }, [props.productVersionId, props.modifyCount]);

  // call the server one by one to process the test cases
  // updating the UI after every one.  If this ends up being too slow we can increaese the concurrency
  useEffect(() => {
    // set up the next before we start the async work to avoid double processing
    const testCaseId = nextTestCaseToExecuteId;
    if (!testCaseId) {
      return;
    }
    const index = testCases.findIndex(tc => tc.id === testCaseId);
    const nextIndex = index + 1;

    const testCase = testCases.find(tc => tc.id === testCaseId);
    if (!testCase) {
      return;
    }
    const eff = async () => {
      if (isExecutionCanceled) {
        setNextTestCaseToExecuteId(undefined);
        return;
      }

      await executeTestCaseAndUpdateList(testCaseId);

      // now that we are done, trigger the next test case
      if (nextIndex < testCases.length) {
        setNextTestCaseToExecuteId(testCases[nextIndex].id);
      } else {
        setNextTestCaseToExecuteId(undefined);
      }
    };
    eff();
  }, [nextTestCaseToExecuteId, isExecutionCanceled]);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const colDefs: GridColDef<TestCaseWithExecutionStatus>[] = [
    {
      field: 'sequenceNumber',
      headerName: 'Test Case Number',
      flex: 200,
      renderCell: params => {
        return (
          <Link to={`/rating/testcase/${params.id}/detail`}>
            {`Test Case ${params.value}`}
          </Link>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 200,
    },
    {
      field: 'expectedPremium',
      headerName: 'Expected Premium',
      flex: 200,
      renderCell: params => currencyFormatter.format(params.value),
    },
    {
      field: 'actualPremium',
      headerName: 'Actual Premium',
      flex: 200,
      renderCell: params => currencyFormatter.format(params.value),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 200,
    },
  ];

  function onTestCaseSelectionChange(newSelection: GridRowSelectionModel) {
    if (newSelection.length === 0) {
      props.setSelectedTestCaseId(undefined);
    } else {
      props.setSelectedTestCaseId(newSelection[0] as string);
    }
  }

  function createTestCase(maxSequenceNumber: number): TestCase {
    return {
      ...NewTenantAwareEntity(),
      sequenceNumber: maxSequenceNumber + 1,
      productVersionId: props.productVersionId || '',
      description: '',
      expectedPremium: '0',
      rootRiskItem: undefined,
    } as TestCase;
  }

  function onCreateNew() {
    const maxSequenceNumber = testCases.reduce((max, testCase) => {
      return testCase.sequenceNumber > max ? testCase.sequenceNumber : max;
    }, 0);

    setEditState({
      open: true,
      testCase: createTestCase(maxSequenceNumber),
    });
  }

  function onCopyTestCase() {
    const maxSequenceNumber = testCases.reduce((max, testCase) => {
      return testCase.sequenceNumber > max ? testCase.sequenceNumber : max;
    }, 0);

    const selectedTestCase = testCases.find(
      tc => tc.id === props.selectedTestCaseId
    );

    if (!selectedTestCase) {
      return;
    }

    const newTestCase = createTestCase(maxSequenceNumber);
    newTestCase.description = selectedTestCase.description;
    newTestCase.expectedPremium = selectedTestCase.expectedPremium;

    setEditState({
      open: true,
      testCase: newTestCase,
      copyFromId: props.selectedTestCaseId,
    });
  }

  async function onModifyTestCase() {
    const selectedTestCase = testCases.find(
      tc => tc.id === props.selectedTestCaseId
    );

    if (!selectedTestCase) {
      return;
    }

    const existingTestCase = await productService.GetProductVersionTestCase(
      selectedTestCase.id
    );

    setEditState({
      open: true,
      testCase: existingTestCase,
    });
  }

  async function onSaveCreateTestCase(testCase: TestCase) {
    if (editState.copyFromId) {
      // copy the test case data from the source
      const copySource = await productService.GetProductVersionTestCase(
        editState.copyFromId
      );
      testCase.rootRiskItem = copySource.rootRiskItem;
    }
    const updated = await productService.UpdateProductVersionTestCase(testCase);
    setEditState({
      ...editState,
      open: false,
    });
    props.setModifyCount(props.modifyCount + 1);
  }

  async function onCancelCreateTestCase() {
    setEditState({
      ...editState,
      open: false,
    });
  }

  async function executeTestCaseAndUpdateList(testCaseId: string) {
    const index = testCases.findIndex(tc => tc.id === testCaseId);

    if (index < 0) {
      return;
    }

    const testCase = testCases[index];
    const testCaseResult =
      await props.ratingService.ExecuteTestCase(testCaseId);
    const updatedTestCase = structuredClone(testCase);
    updatedTestCase.actualPremium = testCaseResult.actualPremium;
    updatedTestCase.status = testCaseResult.passed ? 'Success' : 'Failed';

    const updatedTestCases = [...testCases];
    updatedTestCases.splice(index, 1, updatedTestCase);
    // update it with the status
    setTestCases(updatedTestCases);
  }

  async function onExecuteTestCase() {
    if (!props.selectedTestCaseId) {
      return;
    }

    executeTestCaseAndUpdateList(props.selectedTestCaseId);
  }

  async function onExecuteAllTestCases() {
    setIsExecutionCanceled(false);
    if (testCases.length === 0) {
      return;
    }
    setNextTestCaseToExecuteId(testCases[0].id);
  }

  async function onDeleteTestCase() {
    if (!props.selectedTestCaseId) {
      return;
    }
    await productService.DeleteProductVersionTestCase(props.selectedTestCaseId);
    props.setModifyCount(props.modifyCount + 1);
  }

  const menuOptions: MenuProps['items'] = [
    {
      key: 'add',
      label: 'Add Test Case',
      onClick: onCreateNew,
    },
    {
      key: 'executeAll',
      label: !nextTestCaseToExecuteId
        ? 'Execute All Test Case'
        : 'Cancel Execution',
      onClick: !nextTestCaseToExecuteId
        ? onExecuteAllTestCases
        : () => setIsExecutionCanceled(true),
      disabled: !props.selectedTestCaseId,
    },
    {
      key: 'delete',
      label: (
        <Popconfirm
          title="Confirm deletion"
          description="Are you sure to want to delete?"
          onConfirm={onDeleteTestCase}
          okText="Delete"
          okType="default"
          cancelText="Cancel"
        >
          Delete
        </Popconfirm>
      ),
      disabled: !props.selectedTestCaseId,
    },
    {
      key: 'Modify',
      label: 'Modify',
      onClick: onModifyTestCase,
      disabled: !props.selectedTestCaseId,
    },
    {
      key: 'copy',
      label: 'Copy',
      onClick: onCopyTestCase,
      disabled: !props.selectedTestCaseId,
    },
    {
      key: 'execute',
      label: 'Run Test Case',
      onClick: onExecuteTestCase,
      disabled: !props.selectedTestCaseId,
    },
  ];

  const dropdownButtons = (
    <Dropdown trigger={['click']} menu={{items: menuOptions}}>
      <Button {...ButtonSettings} onClick={e => e.preventDefault()}>
        Actions
      </Button>
    </Dropdown>
  );

  const buttons = props.isReadOnly ? <></> : <>{dropdownButtons}</>;

  return (
    <>
      <Flex vertical style={{width: '100%'}}>
        <Row>
          <Col span={16}></Col>
          <Col span={8}>
            <Flex justify="end" align="start">
              {buttons}
            </Flex>
          </Col>
        </Row>
        <Space direction="vertical" style={{width: '100%'}}>
          <DataGrid
            style={{height: '100%'}}
            {...DataGridSettings}
            columns={colDefs}
            filterMode="client"
            rowSelectionModel={
              props.selectedTestCaseId ? [props.selectedTestCaseId] : []
            }
            onRowSelectionModelChange={onTestCaseSelectionChange}
            rows={testCases}
          />
        </Space>
      </Flex>
      <DraggableModal
        className="adaptify-modal"
        title="Create Test Case"
        open={editState.open}
        closable={false}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        width={800}
      >
        <CreateTestCaseForm
          initialTestCase={editState.testCase}
          onCreateTestCase={onSaveCreateTestCase}
          onCancel={onCancelCreateTestCase}
        />
      </DraggableModal>
    </>
  );
}
