import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import {Flex, Input} from 'antd';
import React, {useEffect, useState} from 'react';
import {DataGridSettings} from '../../../common/control/Common';
import {IdAndName} from '../../../product/model/Product';
import {ProductService} from '../../../product/service/ProductService';
import {DirtyEditorConfirmationModal} from '../DirtyEditorConfirmationModal';

export interface ProductVersionTableListProps {
  productVersionId: string;

  productService: ProductService;
  modifyCount: number;
  selectedTableId: string | undefined;
  setSelectedTableId: (id: string | undefined) => void;
  isEditorDirty: boolean;
}

interface ChangeTableConfirmState {
  open: boolean;
  tableId?: string | undefined;
}

export function ProductVersionTableList(props: ProductVersionTableListProps) {
  // used to trigger a refresh on the UI on change
  const [tableNames, setTableNames] = useState<IdAndName[]>([]);
  const [displayTableNames, setDisplayTableNames] = useState<IdAndName[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');
  const [changeTableConfirmOpen, setChangeTableConfirmOpen] =
    useState<ChangeTableConfirmState>({
      open: false,
      tableId: undefined,
    });
  useEffect(() => {
    const eff = async () => {
      const tableNames =
        await props.productService.GetProductVersionTableNamesByProductVersionId(
          props.productVersionId
        );
      tableNames.sort((a, b) => a.name.localeCompare(b.name));
      setTableNames(tableNames);
      if (!props.selectedTableId && tableNames.length > 0) {
        props.setSelectedTableId(tableNames[0].id);
      }
    };
    eff();
  }, [props.productVersionId, props.modifyCount]);

  useEffect(() => {
    if (filterValue === '') {
      setDisplayTableNames(tableNames);
      return;
    }
    const displayTableNames = tableNames.filter(t =>
      t.name.toLowerCase().includes(filterValue.toLowerCase())
    );
    setDisplayTableNames(displayTableNames);
    props.setSelectedTableId(
      displayTableNames.length > 0
        ? props.selectedTableId
          ? props.selectedTableId
          : displayTableNames[0].id
        : undefined
    );
  }, [filterValue, tableNames]);

  const colDefs: GridColDef<IdAndName>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 200,
    },
  ];

  function onTreeControlSelectionChanged(selection: GridRowSelectionModel) {
    setChangeTableConfirmOpen({
      open: true,
      tableId: selection.length > 0 ? (selection[0] as string) : undefined,
    });
  }

  function onFilterNameChanged(value: string) {
    if (
      props.isEditorDirty &&
      !confirm(
        'Editor has unsaved changes, are you sure you want to change tables?'
      )
    ) {
      return;
    }
    setFilterValue(value);
  }

  return (
    <>
      <Flex vertical gap={15} style={{width: '100%'}}>
        <Input
          placeholder="Enter name search"
          value={filterValue}
          onChange={e => onFilterNameChanged(e.target.value)}
        />
        <div
          className={'ag-theme-quartz'}
          style={{height: '100%', width: '100%'}}
        >
          <DataGrid
            {...DataGridSettings}
            slots={{columnHeaders: () => null}}
            columnHeaderHeight={0}
            columns={colDefs}
            filterMode="client"
            rowHeight={42}
            rowSelectionModel={
              props.selectedTableId ? [props.selectedTableId] : []
            }
            onRowSelectionModelChange={onTreeControlSelectionChanged}
            sx={{
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#F5F5F5',
              },
              '& .MuiDataGrid-cell': {
                border: 0,
              },
              '&.MuiDataGrid-root': {
                borderRadius: '8px',
                overflow: 'hidden',
                borderColor: '#CCCCCC',
              },
            }}
            rows={displayTableNames}
            pageSizeOptions={[17]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 17,
                },
              },
            }}
          />
        </div>
      </Flex>
      <DirtyEditorConfirmationModal
        onCancel={() => {
          setChangeTableConfirmOpen({
            open: false,
            tableId: undefined,
          });
        }}
        onConfirm={() => {
          props.setSelectedTableId(changeTableConfirmOpen.tableId ?? '');
          setChangeTableConfirmOpen({
            open: false,
            tableId: undefined,
          });
          return Promise.resolve();
        }}
        skipDialog={!props.isEditorDirty}
        open={changeTableConfirmOpen.open}
      />
    </>
  );
}
