import {Button, Flex, Form, Input} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings} from '../../../common/control/Common';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import TestCase = kot.com.adaptify.rating.admin.model.testcase.TestCase;

export interface CreateTestCaseFormProps {
  initialTestCase: TestCase;
  onCreateTestCase: (testCase: TestCase) => void;
  onCancel: () => void;
}

interface CreateTestCaseFormValues {
  testCaseName: string;
  description: string;
  expectedPremium: string;
}

export function CreateTestCaseForm(props: CreateTestCaseFormProps) {
  const [form] = Form.useForm<CreateTestCaseFormValues>();
  const [validationInfo, setValidationInfo] = useState<{
    validateStatus?: ValidateStatus;
    errorMsg?: string | null;
  }>({});

  type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];

  useEffect(() => {
    // clear the form if the form is reused
    form.resetFields();

    form.setFieldsValue({
      testCaseName: `Test case ${props.initialTestCase.sequenceNumber}`,
      description: props.initialTestCase.description,
      expectedPremium: props.initialTestCase.expectedPremium ?? '0',
    });
  }, [props.initialTestCase]);

  function onSave(value: CreateTestCaseFormValues) {
    // merge values in the form with the existing object
    props.onCreateTestCase({
      ...props.initialTestCase,
      description: value.description,
      expectedPremium: value.expectedPremium,
    });
  }

  const onOk = () => {
    if (validationInfo.validateStatus !== 'error') {
      form.submit();
    }

    return;
  };

  return (
    <Form
      name="edit_coverage"
      labelWrap={true}
      layout="vertical"
      size="large"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      style={{width: '100%'}}
      onFinish={onSave}
      autoComplete="off"
      form={form}
    >
      <Form.Item name="testCaseName" label="Test Case Number">
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="expectedPremium"
        label="Expected Premium"
        rules={[
          {required: true},
          {
            validator: (_, value) => {
              if (value >= 0) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('Expected Premium should be greater or equal to 0')
              );
            },
          },
        ]}
      >
        <Input maxLength={14} allowClear />
      </Form.Item>
      <Flex justify="end" className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" onClick={onOk}>
          OK
        </Button>
      </Flex>
    </Form>
  );
}
