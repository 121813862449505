package com.adaptify.rating.admin.model.lob

import com.adaptify.rating.admin.model.MetadataObject

@JsExport
external actual interface LineOfBusinessHierarchyItem : MetadataObject {
  actual var itemType : String
  actual var parentLineOfBusinessItemId : String?
  actual var children: Array<LineOfBusinessHierarchyItem>
  actual var fields: Array<LineOfBusinessHierarchyItemField>
  actual var isMany: Boolean
}

actual fun createLineOfBusinessHierarchyItem(
  id: String,
  name: String,
  version: Int,
  itemType : String,
  parentLineOfBusinessItemId : String?,
  children: Array<LineOfBusinessHierarchyItem>,
  fields: Array<LineOfBusinessHierarchyItemField>,
  isMany: Boolean
): LineOfBusinessHierarchyItem {
  return js("{id: id, version: version, itemType: itemType, parentLineOfBusinessItemId: parentLineOfBusinessItemId, children: children, fields: fields, isMany: isMany}")
}
