import {Card, GetRef, Tree, TreeDataNode} from 'antd';
import React, {useEffect, useState} from 'react';
import {LobItemType} from '../../../../lob/model/LineOfBusiness';

import {
  CalendarOutlined,
  CheckSquareOutlined,
  FieldStringOutlined,
  NumberOutlined,
  PartitionOutlined,
} from '@ant-design/icons';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import MetadataProvider = kot.com.adaptify.rating.admin.model.util.MetadataProvider;
import RuleType = kot.com.adaptify.rating.admin.model.type.RuleType;
import Parameter = kot.com.adaptify.rating.admin.model.calculation.Parameter;
import PrimitiveDataType = kot.com.adaptify.rating.admin.model.type.PrimitiveDataType;
import VariableType = kot.com.adaptify.rating.admin.model.calculation.context.VariableType;
import ScopedVariable = kot.com.adaptify.rating.admin.model.calculation.context.ScopedVariable;
import LineOfBusinessHierarchyItem = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchyItem;

export interface PathPickTreeProps {
  rootProperties: ScopedVariable[];
  selectedPath: string | undefined;
  allowedDataTypes: string[];
  setSelectedPath: (path?: string) => void;
  resetVersion: number;
  height: number;
  hideFields?: boolean;
  onSelectPath: () => void;
}

export interface RootProperty {
  type: string;
  name: string;
  displayName: string;
  dataType: string;
}

export function PathPickTree(props: PathPickTreeProps) {
  const initialSelectedKeys = props.selectedPath ? [props.selectedPath] : [];
  const [expandedKeys, setExpandedKeys] =
    useState<React.Key[]>(initialSelectedKeys);
  const [selectedKeys, setSelectedKeys] =
    useState<React.Key[]>(initialSelectedKeys);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);

  type TreeRef = GetRef<typeof Tree>;

  const treeRef = React.useRef<TreeRef>(null);

  useEffect(() => {
    const eff = async () => {
      // reset state
      const selectedKeys = props.selectedPath ? [props.selectedPath] : [];
      setExpandedKeys(selectedKeys);
      setSelectedKeys(selectedKeys);
      setAutoExpandParent(true);
      setTreeData([]);

      const myTreeData: TreeDataNode[] = [];
      for (const rootProp of props.rootProperties) {
        if (rootProp.type !== VariableType.Input.name) {
          // skip dynamic variables and they show up in the steps picker
          // skips variables as they show up as a child node in the tree
          continue;
        }
        myTreeData.push(await generateTreeData(rootProp, props.selectedPath));
      }

      if (!props.hideFields) {
        const globals = props.rootProperties.filter(
          v => v.type === VariableType.Variable.name
        );
        if (globals.length > 0) {
          const globalNode: TreeDataNode = {
            title: 'Global Fields',
            key: '_globals',
            icon: <PartitionOutlined />,
            children: [],
          };
          for (const global of globals) {
            globalNode.children.push(
              await generateTreeData(global, props.selectedPath)
            );
          }
          myTreeData.push(globalNode);
        }
      }

      setTreeData(myTreeData);
    };

    eff();
  }, [props.resetVersion, props.rootProperties]);

  useEffect(() => {
    // scrolling needs to happen after the react lifecycle has completed
    const timeoutId = setTimeout(() => {
      if (treeData && props.selectedPath && treeRef.current) {
        treeRef.current.scrollTo({key: props.selectedPath, align: 'top'});
      }
    }, 300);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  function onExpand(expandedKeys: React.Key[]) {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  }

  function onSelect(selectedKeys: React.Key[]) {
    setSelectedKeys(selectedKeys);

    const selectableKeys = selectedKeys.filter(
      k =>
        !(k as string).endsWith('__fields') &&
        !(k as string).endsWith('__coverages')
    );
    const sel =
      selectableKeys.length > 0 ? selectableKeys[0].valueOf() : undefined;
    props.setSelectedPath(sel as string | undefined);
  }

  function onDoubleClick() {
    props.onSelectPath();
  }

  async function generateTreeData(
    variable: ScopedVariable,
    selection?: string
  ): Promise<TreeDataNode> {
    const key = variable.name;
    return {
      title: variable.name,
      key: key,
      icon: getIconForTreeNode(variable.boundDataType.primitive || undefined),
      children: await generateTreeDataChildren(
        variable.boundDataType.lobItem || undefined,
        key,
        1,
        selection
      ),
    };
  }

  function getPrimitiveDataType(dataType: string) {
    return PrimitiveDataType.values().find(v => v.name === dataType);
  }

  function getIconForTreeNode(dataType: PrimitiveDataType | undefined) {
    if (!dataType) {
      return <PartitionOutlined />;
    }
    if (dataType === PrimitiveDataType.String) {
      return <FieldStringOutlined />;
    }
    if (dataType === PrimitiveDataType.Number) {
      return <NumberOutlined />;
    }
    if (dataType === PrimitiveDataType.Boolean) {
      return <CheckSquareOutlined />;
    }
    if (dataType === PrimitiveDataType.Date) {
      return <CalendarOutlined />;
    }
    return undefined;
  }

  function generateTreeDataChildren(
    parentItem: LineOfBusinessHierarchyItem | undefined,
    parentPath: string,
    level: number,
    selection?: string
  ): TreeDataNode[] {
    if (!parentItem) {
      return [];
    }

    const children: TreeDataNode[] = [];
    const lobChildren = [...parentItem.children];
    lobChildren.sort((a, b) => a.name.localeCompare(b.name));
    const childRisks = lobChildren.filter(c => c.itemType === LobItemType.Risk);
    childRisks.sort((a, b) => a.name.localeCompare(b.name));
    const childCoverages = lobChildren.filter(
      c => c.itemType === LobItemType.Coverage
    );
    childCoverages.sort((a, b) => a.name.localeCompare(b.name));

    if (!props.hideFields) {
      const fields = [...parentItem.fields];
      fields.sort((a, b) => a.name.localeCompare(b.name));
      if (fields.length > 0) {
        const fieldNodes: TreeDataNode[] = [];
        for (const field of fields) {
          // do we allow risks and coverages to have the same name as fields?  if so we need to disambiguate in the path
          const fieldPath = parentPath + '.' + field.name;
          fieldNodes.push({
            title: field.name,
            key: fieldPath,
            icon: getIconForTreeNode(getPrimitiveDataType(field.dataType)),
          });
        }
        // product requirements are risk item fields show up in a container but coverage fields do not
        if (parentItem.itemType === LobItemType.Risk) {
          children.unshift({
            title: `${parentItem.name} Risk Item Fields`,
            key: parentPath + '__fields',
            icon: <PartitionOutlined />,
            children: fieldNodes,
          });
        } else {
          children.push(...fieldNodes);
        }
      }
    }

    const coverageNodes: TreeDataNode[] = [];
    for (const child of childCoverages) {
      const fullPath = parentPath + '.' + child.name;
      coverageNodes.push({
        title: child.name,
        key: fullPath,
        icon: <PartitionOutlined />,
        children: generateTreeDataChildren(
          child,
          fullPath,
          level + 1,
          selection
        ),
      });
    }

    if (coverageNodes.length > 0) {
      const coverageNode: TreeDataNode = {
        title: `${parentItem.name} Coverages`,
        key: parentPath + '__coverages',
        icon: <PartitionOutlined />,
        children: coverageNodes,
      };

      children.push(coverageNode);
    }

    for (const child of childRisks) {
      const fullPath = parentPath + '.' + child.name;
      children.push({
        title: child.name,
        key: fullPath,
        icon: <PartitionOutlined />,
        children: generateTreeDataChildren(
          child,
          fullPath,
          level + 1,
          selection
        ),
      });
    }

    return children;
  }

  return (
    <Card
      bordered={false}
      title="Select Path"
      style={{width: '100%', height: '100%'}}
    >
      <Tree
        height={props.height}
        showIcon
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onSelect={onSelect}
        onDoubleClick={onDoubleClick}
        selectedKeys={selectedKeys}
        treeData={treeData}
        virtual={false}
        ref={treeRef}
      />
    </Card>
  );
}
