import {Button, Col, Menu, MenuProps, Row} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';

export interface RatingNavigationBarForDiffProps {
  selection: string;
  productVersionIdOne: string;
  productVersionIdTwo: string;
}

export function RatingNavigationBarForDiff(
  props: RatingNavigationBarForDiffProps
) {
  const navigate = useNavigate();
  const navItems: MenuProps['items'] = [
    {
      key: 'Table',
      label: (
        <Button
          type="text"
          onClick={async () => {
            navigate(
              `/rating/product/version/${props.productVersionIdOne}/${props.productVersionIdTwo}/tablediff`
            );
          }}
        >
          Tables
        </Button>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span="24">
          <Menu
            mode="horizontal"
            selectedKeys={[props.selection]}
            items={navItems}
          />
        </Col>
      </Row>
    </>
  );
}
