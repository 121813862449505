import {Col, Flex, Input, Row, Typography} from 'antd';
import React, {useEffect, useState} from 'react';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import Select, {BaseOptionType} from 'antd/es/select';
import {LobItemType} from '../../../lob/model/LineOfBusiness';
import {ProductService} from '../../../product/service/ProductService';
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import LineOfBusinessHierarchyItem = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchyItem;

export interface CoverageSelectionFormProps {
  productService: ProductService;
  productVersionId: string | undefined;

  selectedCoverageId: string | undefined;
  setSelectedCoverageId: (coverageId: string) => void;
}

export function CoverageSelectionForm(props: CoverageSelectionFormProps) {
  const [selectedRiskId, setSelectedRiskId] = useState<string | undefined>(
    undefined
  );

  const [lobHierarchy, setLobHierarchy] = useState<
    LineOfBusinessHierarchy | undefined
  >();

  const riskOptions = buildRiskSelectOptionsForHierarhcy(lobHierarchy);
  const coverage =
    lobHierarchy && props.selectedCoverageId
      ? findLobItemInHierarchy(lobHierarchy, props.selectedCoverageId)
      : undefined;

  const coverageOptions = buildCoverageSelectOptionsForRiskId(
    lobHierarchy,
    selectedRiskId
  );

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionId) {
        setLobHierarchy(undefined);
        return;
      }

      const loaded =
        await props.productService.GetLobHierarchyForProductVersionId(
          props.productVersionId
        );
      setLobHierarchy(loaded);
    };
    eff();
  }, [props.productVersionId]);

  useEffect(() => {
    if (!lobHierarchy) {
      return;
    }

    if (!props.selectedCoverageId) {
      // if there's not selected coverage set the risk id to the first available
      setSelectedRiskId(riskOptions[0]?.value);
      return;
    }
    const coverage = findLobItemInHierarchy(
      lobHierarchy,
      props.selectedCoverageId
    );
    setSelectedRiskId(coverage?.parentLineOfBusinessItemId || undefined);
  }, [props.selectedCoverageId, lobHierarchy]);

  useEffect(() => {
    // try to default the coverage when the risk changes
    if (!selectedRiskId || !lobHierarchy) {
      return;
    }
    if (!props.selectedCoverageId && coverageOptions.length > 0) {
      props.setSelectedCoverageId(coverageOptions[0].value);
    }
    if (coverage && coverage.parentLineOfBusinessItemId !== selectedRiskId) {
      // if risk changes, set coverage to the first
      props.setSelectedCoverageId(coverageOptions[0].value);
    }
  }, [selectedRiskId, lobHierarchy]);

  return (
    <Flex vertical>
      <div
        style={{
          backgroundColor: '#F2F7FE',
        }}
      >
        <div
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <Row gutter={[10, 16]}>
            <Col span={2} className="adaptify-label-column">
              <Typography.Text>Risk Item:</Typography.Text>
            </Col>
            <Col span={3}>
              <Select
                style={{width: '100%'}}
                value={selectedRiskId}
                options={riskOptions}
                onChange={setSelectedRiskId}
              ></Select>
            </Col>
            <Col span={2} className="adaptify-label-column">
              <Typography.Text>Coverage:</Typography.Text>
            </Col>
            <Col span={8}>
              <Select
                style={{width: '100%'}}
                value={props.selectedCoverageId}
                options={coverageOptions}
                onChange={props.setSelectedCoverageId}
              ></Select>
            </Col>
            <Col span={2} className="adaptify-label-column">
              <Typography.Text>Code:</Typography.Text>
            </Col>
            <Col span={2}>
              <Input className="adaptify-read-only-field" readOnly />
            </Col>
            <Col span={2} className="adaptify-label-column">
              <Typography.Text>Cardinality:</Typography.Text>
            </Col>
            <Col span={2}>
              <Input
                value={coverage?.isMany ? 'Multi' : 'Single'}
                className="adaptify-read-only-field"
                readOnly
              ></Input>
            </Col>
          </Row>
        </div>
      </div>
    </Flex>
  );
}

function buildCoverageSelectOptionsForRiskId(
  lobHierarchy: LineOfBusinessHierarchy | undefined,
  lobItemId: string | undefined
): BaseOptionType[] {
  if (!lobHierarchy || !lobItemId) {
    return [];
  }

  const risk = findLobItemInHierarchy(lobHierarchy, lobItemId);

  const options = (risk?.children || [])
    .filter(r => r.itemType === LobItemType.Coverage)
    .map(r => {
      return {
        label: r.name,
        value: r.id,
      } as BaseOptionType;
    });
  options.sort((a, b) => a.label.localeCompare(b?.label));
  return options;
}

function findLobItemInHierarchy(
  lobHierarchy: LineOfBusinessHierarchy,
  lobItemId: string
): LineOfBusinessHierarchyItem | undefined {
  for (const risk of lobHierarchy.risks || []) {
    const found = findLobItem(risk, lobItemId);
    if (found) {
      return found;
    }
  }
}

function findLobItem(
  lobHierarchy: LineOfBusinessHierarchyItem,
  lobItemId: string
): LineOfBusinessHierarchyItem | undefined {
  if (lobHierarchy.id === lobItemId) {
    return lobHierarchy;
  }
  for (const child of lobHierarchy.children || []) {
    const found = findLobItem(child, lobItemId);
    if (found) {
      return found;
    }
  }
}

function buildRiskSelectOptionsForHierarhcy(
  lobHierarchy: LineOfBusinessHierarchy | undefined
): BaseOptionType[] {
  if (!lobHierarchy) {
    return [];
  }
  const options = [];
  (lobHierarchy.risks || []).forEach(r => {
    buildRiskSelectOptionsForRiskItem(r, options);
  });
  return options;
}

function buildRiskSelectOptionsForRiskItem(
  item: LineOfBusinessHierarchyItem,
  options: BaseOptionType[]
) {
  if (item.itemType !== LobItemType.Risk) {
    return;
  }
  const hasCoverage = item.children?.some(
    c => c.itemType === LobItemType.Coverage
  );
  if (hasCoverage) {
    // only populate the risk dropdown for risk that have coverages
    options.push({
      label: item.name,
      value: item.id,
    });
  }

  item?.children?.forEach(c => {
    buildRiskSelectOptionsForRiskItem(c, options);
  });
}
