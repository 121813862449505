import {Form, Input} from 'antd';
import React, {useEffect} from 'react';
import {ButtonSettings} from '../../../common/control/Common';
import {DraggableModal} from '../../../common/control/DraggableModal';

export interface CopyTableModalProps {
  productVersionId: string;
  sourceTableName: string;

  onCopy: (
    productVersionId: string,
    sourceTableName: string,
    newTableName: string
  ) => void;
  onCancel: () => void;
}

interface CopyTableFormValues {
  newTableName: string;
}

export function CopyTableModal(props: CopyTableModalProps) {
  const [form] = Form.useForm<CopyTableFormValues>();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({newTableName: props.sourceTableName + ' Copy'});
  }, [props.sourceTableName]);

  function onCopy(values: CopyTableFormValues) {
    props.onCopy(
      props.productVersionId,
      props.sourceTableName,
      values.newTableName
    );
  }

  return (
    <DraggableModal
      className="adaptify-modal"
      width={'clamp(300px, 70svw, 1200px)'}
      open={true}
      title={'Copy Table'}
      onCancel={props.onCancel}
      cancelButtonProps={{...ButtonSettings}}
      okButtonProps={{...ButtonSettings, ghost: false, type: 'default'}}
      onOk={form.submit}
    >
      <Form
        name="copy_table_name"
        labelWrap={true}
        layout="vertical"
        size="large"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        onFinish={onCopy}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          name="newTableName"
          label="New Table Name"
          rules={[{required: true}]}
        >
          <Input></Input>
        </Form.Item>
      </Form>
    </DraggableModal>
  );
}
