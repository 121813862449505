import {CustomHeaderProps} from 'ag-grid-react';
import {Dropdown, MenuProps} from 'antd';
import React from 'react';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import {ConfirmationModal} from '../../../common/control/ConfirmationModal';
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import Column = kot.com.adaptify.rating.admin.model.table.Column;
import ColumnType = kot.com.adaptify.rating.admin.model.table.ColumnType;

// only the commercial version of ag-grid supports a context menu
// so we need to roll our own
export interface CustomTableHeaderWithMenuProps extends CustomHeaderProps {
  table: Table;
  columnIndex: number;
  onColumnsChange: (Table) => void;
  onAddColumn: (columnIndex: number) => void;
  onEditColumn: (column: Column, columnIndex: number) => void;
  readOnly: boolean;
}

interface DeleteColumnConfirmState {
  open: boolean;
  columnIndex: number;
}

export function SwapColumnLocation(
  table: Table,
  oldIndex: number,
  newIndex: number
): Table | undefined {
  if (newIndex < 0 || newIndex >= table.columns.length) {
    return undefined;
  }

  const updated = structuredClone(table);

  const tempCol = updated.columns[oldIndex];
  updated.columns[oldIndex] = updated.columns[newIndex];
  updated.columns[newIndex] = tempCol;
  (updated.rows || []).forEach(row => {
    if (row.length >= oldIndex && row.length >= newIndex) {
      const tempRow = row[oldIndex];
      row[oldIndex] = row[newIndex];
      row[newIndex] = tempRow;
    }
  });
  return updated;
}

export function CustomTableHeaderWithMenu(
  props: CustomTableHeaderWithMenuProps
) {
  const [deleteColumnConfirm, setDeleteColumnConfirm] = React.useState(false);

  function openAddColumn(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    props.onAddColumn(props.columnIndex);
  }

  function openEditColumn(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    props.onEditColumn(
      props.table.columns[props.columnIndex],
      props.columnIndex
    );
  }

  function onDeleteColumn() {
    const updated = structuredClone(props.table);
    updated.columns?.splice(props.columnIndex, 1);
    // also remove all of the row data to match the columns
    updated.rows?.forEach(row => {
      if (row.length > (updated.columns?.length || 0)) {
        row.splice(props.columnIndex, 1);
      }
    });
    props.onColumnsChange(updated);
  }

  function onMoveColumnLeft() {
    swapColumnLocation(props.columnIndex, props.columnIndex - 1);
  }

  function onMoveColumnRight() {
    swapColumnLocation(props.columnIndex, props.columnIndex + 1);
  }

  function swapColumnLocation(oldIndex: number, newIndex: number) {
    if (!props.table) {
      return;
    }

    const updated = SwapColumnLocation(props.table, oldIndex, newIndex);
    if (updated) {
      props.onColumnsChange(updated);
    }
  }

  if (props.table.columns.length === 0) {
    return (
      <>
        <div>
          <a onClick={openAddColumn} style={{textWrap: 'wrap'}}>
            Add New Column
          </a>
        </div>
      </>
    );
  }

  if (props.columnIndex < 0 && !props.readOnly) {
    // if this is the last column do the same thing as an empty table, but show a + instead
    return (
      <>
        <div style={{width: '100%', textAlign: 'center'}}>
          <a onClick={openAddColumn} style={{textWrap: 'wrap'}}>
            +
          </a>
        </div>
      </>
    );
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={openAddColumn}>
          Add new column
        </a>
      ),
      key: '0',
    },
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={openEditColumn}>
          Edit column
        </a>
      ),
      key: '1',
      disabled: props.columnIndex === -1,
    },
    {
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setDeleteColumnConfirm(true)}
        >
          Delete column
        </a>
      ),
      key: '2',
      disabled: props.columnIndex === -1,
    },
    {
      type: 'divider',
    },
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={onMoveColumnLeft}>
          Move Column Left
        </a>
      ),
      key: '3',
      disabled: props.columnIndex <= 0,
    },
    {
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={onMoveColumnRight}
        >
          Move Column Right
        </a>
      ),
      key: '4',
      disabled:
        props.columnIndex < 0 ||
        props.columnIndex >= props.table.columns.length - 1,
    },
  ];

  let colName = 'Add New Column';
  if (props.columnIndex >= 0) {
    colName = props.table.columns[props.columnIndex].name || '';
    if (colName.trim() === '') {
      colName = 'Add Name';
    }
  }

  const deleteColumnConfirmModal = deleteColumnConfirm ? (
    <ConfirmationModal
      open={deleteColumnConfirm}
      title="Delete Column"
      message="Are you sure you would like to delete this column?"
      onOk={() => {
        onDeleteColumn();
        setDeleteColumnConfirm(false);
      }}
      onCancel={() => setDeleteColumnConfirm(false)}
    />
  ) : (
    <> </>
  );

  let color = 'black';
  if (
    props.columnIndex >= 0 &&
    props.columnIndex < props.table.columns.length
  ) {
    const column = props.table.columns[props.columnIndex];
    if (column.columnType === ColumnType.Input.name) {
      color = 'green';
    }
    if (column.columnType === ColumnType.Output.name) {
      color = 'red';
    }
  }

  const headerTitleControl = props.readOnly ? (
    <a onClick={openEditColumn} style={{textWrap: 'wrap', color: color}}>
      {colName}
    </a>
  ) : (
    <Dropdown trigger={['click']} menu={{items}}>
      <a
        onClick={e => e.preventDefault()}
        style={{textWrap: 'wrap', color: color}}
      >
        {colName}
      </a>
    </Dropdown>
  );

  return (
    <>
      <div>{headerTitleControl}</div>
      {deleteColumnConfirmModal}
    </>
  );
}
