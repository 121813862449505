import {
  EventSourceMessage,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import {SERVER_BASE_URL, US_STATES} from '../../common/service/Constants';
import {CreateStandardHeaders} from '../../common/service/Service';
import {
  Conversation,
  Message,
  MetadataDetails,
  SendMessageArgs,
  StreamHandler,
} from '../model/Chat';

const baseUrl = new URL(SERVER_BASE_URL);

const GET_CONVERSATIONS_URL = () => `${baseUrl}/chat/conversations`;
const GET_MESSAGES_URL = (conversationId: string) =>
  `${baseUrl}/chat/conversations/${conversationId}/messages`;
const CREATE_CONVERSATION_URL = () => `${baseUrl}/chat/conversations`;
const SEND_MESSAGE_URL = (conversationId: string) =>
  `${baseUrl}/chat/conversations/${conversationId}/messages`;
const GET_DOCUMENT_METADATA_URL = () => `${baseUrl}/chat/metadata`;
const DELETE_CONVERSATIONS_URL = () => `${baseUrl}/chat/conversations/delete`;

export interface ChatService {
  GetConversations(): Promise<Conversation[]>;
  GetMessagesForConversation(conversationId: string): Promise<Message[]>;
  SendMessage(
    args: SendMessageArgs,
    handler: StreamHandler,
    controller: AbortController
  ): Promise<void>;
  GetDocumentMetdata(): Promise<MetadataDetails>;
  DeleteConversations(): Promise<void>;
}

export const NewChatService = (authToken: string): ChatService => {
  const createHeaders = () => {
    return CreateStandardHeaders(authToken);
  };
  const GetConversations = async (): Promise<Conversation[]> => {
    console.log('GetConversations');
    const errorMessage =
      'An error ocurred while fetching your conversation history';
    try {
      const response = await fetch(GET_CONVERSATIONS_URL(), {
        method: 'GET',
        headers: createHeaders(),
      });
      if (!response.ok) {
        throw new Error(errorMessage);
      }
      const result = (await response.json()) as Conversation[];
      return result.reverse();
    } catch (e) {
      throw new Error(errorMessage);
    }
  };

  const DeleteConversations = async (): Promise<void> => {
    console.log('DeleteConversations');
    const errorMessage =
      'An error ocurred while deleting your conversation history';
    try {
      const response = await fetch(DELETE_CONVERSATIONS_URL(), {
        method: 'DELETE',
        headers: createHeaders(),
        body: JSON.stringify({}),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(errorMessage);
      }
    } catch (e) {
      throw new Error(errorMessage);
    }
  };

  const GetMessagesForConversation = async (
    conversationId: string
  ): Promise<Message[]> => {
    console.log('GetMessagesForConversation');
    const errorMessage =
      'An error ocurred while fetching messages for this conversation';
    try {
      const response = await fetch(GET_MESSAGES_URL(conversationId), {
        method: 'GET',
        headers: createHeaders(),
      });

      if (!response.ok) {
        throw new Error(errorMessage);
      }
      return await response.json();
    } catch (e) {
      throw new Error(errorMessage);
    }
  };
  const SendMessage = async (
    args: SendMessageArgs,
    handler: StreamHandler,
    controller: AbortController
  ): Promise<void> => {
    console.log('SendMessage');
    const url = args.conversationId
      ? SEND_MESSAGE_URL(args.conversationId)
      : CREATE_CONVERSATION_URL();

    if (!args.metadata) {
      const errorMessage =
        'Please use the filter for Carrier, line of business and state from the top of the chat or use the chat suggestions using @ symbol to get the the appropriate responses';
      handler.onError(new Error(errorMessage));
      throw new Error(errorMessage);
    }
    return await fetchEventSource(url, {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        message: args.message,
        metadata: args.metadata,
      }),
      signal: controller.signal,
      async onopen(response: Response) {
        if (!response.ok) {
          const errorMessage = 'An error ocurred while sending your message';
          handler.onError(new Error(errorMessage));
          throw new Error(errorMessage);
        }
      },
      onmessage(event: EventSourceMessage) {
        if (event.event && event.data) {
          handler.onMessage(event.event, JSON.parse(event.data));
        }
      },
      onclose() {
        handler.onClose();
        controller.abort();
      },
      onerror(err: Error) {
        handler.onError(err);
        throw err;
      },
    });
  };
  const GetDocumentMetdata = async (): Promise<MetadataDetails> => {
    console.log('GetDocumentMetdata');
    const errorMessage = 'An error ocurred while fetching document metdata';
    try {
      const response = await fetch(GET_DOCUMENT_METADATA_URL(), {
        method: 'GET',
        headers: createHeaders(),
      });
      if (!response.ok) {
        throw new Error(errorMessage);
      }
      const result = await response.json();
      const companies: Record<
        string,
        {
          lineOfBusiness: Record<
            string,
            {value: string; displayValue: string; states: string[]}[]
          >;
        }
      > = {};
      const metadataDetail: MetadataDetails = {companies: {}};
      if (null !== result) {
        for (const item of result) {
          const companyName = item.carrier;
          const lineOfBusiness = item.insurance_type;
          const lobValue = item.insurance_type || '';
          const lobDisplayValue = getNameForAutoBasedOnMainLOB(
            item.insurance_type,
            item.line_of_business
          );
          const state = US_STATES[item.state.toUpperCase()] || '';
          if (!metadataDetail.companies[companyName]) {
            metadataDetail.companies[companyName] = {lineOfBusiness: {}};
          }
          if (!metadataDetail.companies[companyName]) {
            metadataDetail.companies[companyName] = {lineOfBusiness: {}};
          }

          if (
            !metadataDetail.companies[companyName].lineOfBusiness[
              lineOfBusiness
            ]
          ) {
            metadataDetail.companies[companyName].lineOfBusiness[
              item.insurance_type
            ] = {
              value: lobValue,
              displayValue: lobDisplayValue,
              states: [state],
            };
          }

          const existingBusiness = Object.values(
            metadataDetail.companies[companyName].lineOfBusiness
          ).find(b => b.value === lobValue);

          if (existingBusiness) {
            if (!existingBusiness.states.includes(state)) {
              existingBusiness.states.push(state);
            }
          } else {
            Object.values(
              metadataDetail.companies[companyName].lineOfBusiness
            ).push({
              value: lobValue,
              displayValue: lobDisplayValue,
              states: [state],
            });
          }
        }
      }

      return metadataDetail;
    } catch (e) {
      throw new Error(errorMessage);
    }
  };

  const getNameForAutoBasedOnMainLOB = (insuranceType: string, lob: string) => {
    if (insuranceType === 'Commercial Lines' && lob === 'Auto') {
      return 'Commercial Auto';
    }
    return lob;
  };
  return {
    GetConversations,
    GetMessagesForConversation,
    SendMessage,
    GetDocumentMetdata,
    DeleteConversations,
  };
};
