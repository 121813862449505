import React from 'react';
import {useParams} from 'react-router-dom';
import {LobService} from '../../lob/service/LobService';
import {NavigationAware} from '../../NavigationAware';
import {ProductService} from '../../product/service/ProductService';
import {AuthManager} from '../../user/util/AuthManager';
import {RatingService} from '../service/RatingService';
import {ProductVersionTableDiffControl} from './ProductVersionTableDiffControl';

export interface ProductVersionTableDiffPageProps extends NavigationAware {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  authToken: string;
  authManager: AuthManager;
}
export function ProductVersionTableDiffPage(
  props: ProductVersionTableDiffPageProps
) {
  const {productVersionIdOne, productVersionIdTwo} = useParams();

  if (
    typeof productVersionIdOne !== 'string' ||
    typeof productVersionIdTwo !== 'string'
  ) {
    return <></>;
  }

  function onTableSelected(tableName: string | undefined) {}

  return (
    <ProductVersionTableDiffControl
      lobService={props.lobService}
      productService={props.productService}
      ratingService={props.ratingService}
      productVersionIdOne={productVersionIdOne as string}
      productVersionIdTwo={productVersionIdTwo as string}
      authToken={props.authToken}
      authManager={props.authManager}
    />
  );
}
