import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Button, Flex, Form, Input, Select} from 'antd';
import {RuleObject} from 'antd/es/form';
import React, {useEffect} from 'react';
import {ButtonSettings, yesNoTypeOptions} from '../../common/control/Common';
import {HttpError} from '../../common/service/Service';
import {LineOfBusinessItemField} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';
import PrimitiveDataType = kot.com.adaptify.rating.admin.model.type.PrimitiveDataType;

export interface EditLobItemFieldFormProps {
  lobService: LobService;
  lobItemField: LineOfBusinessItemField | undefined;
  allLobItemsFields: LineOfBusinessItemField[]; // needed for uniqueness check
  reservedFieldNames?: string[];
  onSave: (lobItemField: LineOfBusinessItemField) => void;
  onCancel: () => void;
}

export function EditLobItemFieldForm(props: EditLobItemFieldFormProps) {
  const [form] = Form.useForm<LineOfBusinessItemField>();
  useEffect(() => {
    form.resetFields();
    if (props.lobItemField) {
      form.setFieldsValue(props.lobItemField);
    }
  }, [props.lobItemField]);

  const primitiveTypeOptions = [
    PrimitiveDataType.Boolean,
    PrimitiveDataType.Date,
    PrimitiveDataType.Number,
    PrimitiveDataType.String,
  ].map(v => ({label: v.name, value: v.name}));

  async function onSave(value: LineOfBusinessItemField) {
    // merge values in the form with the existing object

    const field = {
      ...props.lobItemField,
      ...value,
    };

    const updated = await props.lobService
      .UpdateLineOfBusinessItemField(field)
      .catch((e: HttpError) => {
        form.setFields([
          {
            name: 'name',
            errors: [e.details?.message ?? e.message],
          },
        ]);
        return undefined;
      });
    if (updated) {
      props.onSave(updated);
    }
  }

  function validateFieldNameUniqueness(
    rule: RuleObject,
    value: string
  ): Promise<void> {
    const usedFieldNames = props.allLobItemsFields
      .filter(v => v.id !== props.lobItemField?.id)
      .map(v => v.name);
    if (usedFieldNames.includes(value)) {
      return Promise.reject('Field name must be unique');
    }
    return Promise.resolve();
  }

  function validateReservedFields(
    rule: RuleObject,
    value: string
  ): Promise<void> {
    const usedFieldNames = props.allLobItemsFields
      .filter(v => v.id !== props.lobItemField?.id)
      .map(v => v.name);
    if (props.reservedFieldNames && props.reservedFieldNames.includes(value)) {
      return Promise.reject('Field name is reserved');
    }
    return Promise.resolve();
  }

  return (
    <Form
      name="edit_risk_item_field"
      layout="vertical"
      size="large"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      labelWrap={true}
      style={{width: '100%'}}
      initialValues={{...props.lobItemField}}
      onFinish={onSave}
      autoComplete="off"
      form={form}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {required: true},
          {
            validator: validateFieldNameUniqueness,
          },
          {
            validator: validateReservedFields,
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>
      <Form.Item name="dataType" label="Data Type" rules={[{required: true}]}>
        <Select
          showSearch
          optionFilterProp="label"
          options={primitiveTypeOptions}
        />
      </Form.Item>
      <Form.Item name="ratingInput" label="Rating Input">
        <Select
          showSearch
          optionFilterProp="label"
          options={yesNoTypeOptions}
        />
      </Form.Item>
      <Flex style={{width: '100%'}} justify="end" className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" htmlType="submit">
          OK
        </Button>
      </Flex>
    </Form>
  );
}
