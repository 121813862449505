import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Col, Flex, Input, Row, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {LobService} from '../../lob/service/LobService';
import {ProductSummaryForm} from '../../product/control/ProductSummaryForm';
import {ProductVersionSummary} from '../../product/model/Product';
import {ProductService} from '../../product/service/ProductService';
import {AuthManager} from '../../user/util/AuthManager';
import {RatingNavigationBarForDiff} from '../control/RatingNavigationBarForDiff';
import {ProductVersionTableDiffDetails} from '../control/table/ProductVersionTableDiffDetails';
import {ProductVersionTableDiffList} from '../control/table/ProductVersionTableDiffList';
import {RatingService} from '../service/RatingService';
import Table = kot.com.adaptify.rating.admin.model.table.Table;

export interface ProductVersionTableDiffControlProps {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  productVersionIdOne: string;
  productVersionIdTwo: string;
  authToken: string;
  authManager: AuthManager;
}

export function ProductVersionTableDiffControl(
  props: ProductVersionTableDiffControlProps
) {
  const [selectedTableName, setSelectedTableName] = useState<
    string | undefined
  >(undefined);
  const [table1, setTable1] = useState<Table | undefined>(undefined);
  const [table2, setTable2] = useState<Table | undefined>(undefined);
  const [productId, setProductId] = useState<string>();
  const [productVersionOneSummary, setProductVersionOneSummary] =
    useState<ProductVersionSummary>();
  const [productVersionTwoSummary, setProductVersionTwoSummary] =
    useState<ProductVersionSummary>();
  const [diffTable1, setDiffTable1] = useState<boolean[][] | undefined>(
    undefined
  );
  const [diffTable2, setDiffTable2] = useState<boolean[][] | undefined>(
    undefined
  );

  useEffect(() => {
    const eff = async () => {
      const tableName = selectedTableName ? selectedTableName : '';
      if (!tableName) {
        doSetSelectedTableName(tableName);
      }

      const productVersionOneSummary =
        await props.productService.GetProductVersionSummaryById(
          props.productVersionIdOne
        );
      setProductId(productVersionOneSummary.productId);
      setProductVersionOneSummary(productVersionOneSummary);
      const tableOnes =
        await props.productService.GetProductVersionTablesByProductVersionIdTableName(
          props.productVersionIdOne,
          !selectedTableName ? '' : selectedTableName
        );
      let table1Exist = false;
      let table2Exist = false;

      if (tableOnes !== undefined && tableOnes.length >= 1) {
        table1Exist = true;
      }

      const productVersionTwoSummary =
        await props.productService.GetProductVersionSummaryById(
          props.productVersionIdTwo
        );
      setProductVersionTwoSummary(productVersionTwoSummary);
      const tableTwos =
        await props.productService.GetProductVersionTablesByProductVersionIdTableName(
          props.productVersionIdTwo,
          !selectedTableName ? '' : selectedTableName
        );
      if (tableTwos !== undefined && tableTwos.length >= 1) {
        table2Exist = true;
      }

      let diffTable: boolean[][] = [];
      if (table1Exist && !table2Exist) {
        setTable1(tableOnes[0]);
        setTable2(undefined);
        diffTable = initializeTableDiffToAllTrueOrFalse(tableOnes[0], false);
        setDiffTable1(diffTable);
        setDiffTable2(undefined);
      } else if (!table1Exist && table2Exist) {
        setTable1(undefined);
        setTable2(tableTwos[0]);
        diffTable = initializeTableDiffToAllTrueOrFalse(tableTwos[0], false);
        setDiffTable1(undefined);
        setDiffTable2(diffTable);
      } else if (table1Exist && table2Exist) {
        setTable1(tableOnes[0]);
        setTable2(tableTwos[0]);
        diffTable = initializeTableDiffToAllTrueOrFalse(tableOnes[0], true);
        populateDiffTable(tableOnes[0], tableTwos[0], diffTable);
        setDiffTable1(diffTable);
        diffTable = initializeTableDiffToAllTrueOrFalse(tableTwos[0], true);
        populateDiffTable(tableTwos[0], tableOnes[0], diffTable);
        setDiffTable2(diffTable);
      } else {
        setTable1(undefined);
        setTable2(undefined);
        setDiffTable1(undefined);
        setDiffTable2(undefined);
      }
    };
    eff();
  }, [selectedTableName]);

  function doSetSelectedTableName(name: string | undefined) {
    setSelectedTableName(name);
  }

  function initializeTableDiffToAllTrueOrFalse(
    table: Table,
    defaultValue: boolean
  ): boolean[][] {
    const returnDiff: boolean[][] = [];
    const tableRowNumber = table.rows.length;
    const tableColumnNumber = table.columns.length;
    for (let i = 0; i <= tableRowNumber - 1; i++) {
      if (!returnDiff[i]) {
        returnDiff[i] = [];
      }
      for (let j = 0; j <= tableColumnNumber - 1; j++) {
        returnDiff[i].push(defaultValue);
      }
    }

    return returnDiff;
  }

  /**
   * This is to set diffTable for table1. true means the value of table1 equals the value of table2 at the same cell
   * @param table1
   * @param table2
   * @param diffTable
   * @returns
   */
  function populateDiffTable(
    table1: Table,
    table2: Table,
    diffTable: boolean[][]
  ) {
    const table1RowNumber = table1.rows.length;
    const table2RowNumber = table2.rows.length;
    const tableRowNumber = Math.min(table1RowNumber, table2RowNumber);
    const table1ColumnNumber = table1.columns.length;
    const table2ColumnNumber = table2.columns.length;
    const tableColumnNumber = Math.min(table1ColumnNumber, table2ColumnNumber);
    for (let i = 0; i <= tableRowNumber - 1; i++) {
      for (let j = 0; j <= tableColumnNumber - 1; j++) {
        if (table1.rows[i][j] !== table2.rows[i][j]) {
          diffTable[i][j] = true;
        } else {
          diffTable[i][j] = false;
        }
      }
    }
  }

  const inputStyle = {background: '#EEEEEE'};

  return (
    <Flex vertical gap={24}>
      <div style={{height: '100%', width: '100%'}}>
        <ProductSummaryForm
          productService={props.productService}
          productId={productId}
          showVersionDetailButton={true}
        />
      </div>
      <Flex>
        <div style={{width: '100%'}}>
          <div
            style={{
              display: 'inline-block',
              width: '20%',
              height: '100%',
              paddingRight: '10px',
              overflow: 'auto',
            }}
          ></div>
          <div style={{display: 'inline-block', width: '38%'}}>
            <Row gutter={[10, 16]}>
              <Col span={4} className="adaptify-label-column">
                <Typography.Text>Effective Date:</Typography.Text>
              </Col>
              <Col span={6}>
                <Input
                  value={productVersionOneSummary?.effectiveDate}
                  readOnly
                  style={inputStyle}
                ></Input>
              </Col>
              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Version:</Typography.Text>
              </Col>
              <Col span={6}>
                <Input
                  value={productVersionOneSummary?.versionNumber}
                  readOnly
                  style={inputStyle}
                ></Input>
              </Col>
            </Row>
          </div>
          <div style={{display: 'inline-block', width: '4%'}}></div>
          <div style={{display: 'inline-block', width: '38%'}}>
            <Row gutter={[10, 16]}>
              <Col span={4} className="adaptify-label-column">
                <Typography.Text>Effective Date:</Typography.Text>
              </Col>
              <Col span={6}>
                <Input
                  value={productVersionTwoSummary?.effectiveDate}
                  readOnly
                  style={inputStyle}
                ></Input>
              </Col>
              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Version:</Typography.Text>
              </Col>
              <Col span={6}>
                <Input
                  value={productVersionTwoSummary?.versionNumber}
                  readOnly
                  style={inputStyle}
                ></Input>
              </Col>
            </Row>
          </div>
        </div>
      </Flex>
      <div style={{minHeight: '46px', width: '100%'}}>
        <RatingNavigationBarForDiff
          selection="Table"
          productVersionIdOne={props.productVersionIdOne}
          productVersionIdTwo={props.productVersionIdTwo}
        />
      </div>
      <Flex>
        <div style={{width: '100%'}}>
          <div
            style={{
              display: 'inline-block',
              width: '20%',
              height: '100%',
              paddingRight: '10px',
              overflow: 'auto',
            }}
          >
            <ProductVersionTableDiffList
              productService={props.productService}
              productVersionIdOne={props.productVersionIdOne}
              productVersionIdTwo={props.productVersionIdTwo}
              setSelectedTableName={doSetSelectedTableName}
              selectedTableName={selectedTableName}
            />
          </div>

          <div style={{display: 'inline-block', width: '38%'}}>
            <ProductVersionTableDiffDetails
              productVersionId={props.productVersionIdOne}
              productVersionTable={table1}
              diffFlags={diffTable1}
            />
          </div>
          <div style={{display: 'inline-block', width: '4%'}}></div>
          <div style={{display: 'inline-block', width: '38%'}}>
            <ProductVersionTableDiffDetails
              productVersionId={props.productVersionIdOne}
              productVersionTable={table2}
              diffFlags={diffTable2}
            />
          </div>
        </div>
      </Flex>
    </Flex>
  );
}
