import {ColDef} from '@ag-grid-community/core';
import {AgGridReact} from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import * as kot from 'adaptify-multi-module-rating-admin-model';
import React, {useEffect, useMemo, useState} from 'react';
import Table = kot.com.adaptify.rating.admin.model.table.Table;

export interface ProductVersionTableDiffDetailsProps {
  productVersionId: string;
  productVersionTable?: Table;
  tableHeight?: string;
  diffFlags?: boolean[][];
}

interface RowData {
  id: string;
  data: String[];
}

export function ProductVersionTableDiffDetails(
  props: ProductVersionTableDiffDetailsProps
) {
  const [colDefs, setColDefs] = useState([] as ColDef<RowData>[]);
  const [rows, setRows] = useState([] as RowData[]);

  function getColumns(): ColDef[] {
    if (!props.productVersionTable) {
      return [];
    }

    const gridColumns: ColDef[] = props.productVersionTable.columns.map(
      (col, index) => {
        return {
          field: col.name,
          headerName: col.name,
          width: col.name?.length,
          valueGetter: row => {
            return row.data?.data[index] || '';
          },
          cellStyle: function (params) {
            if (props.diffFlags) {
              if (props.diffFlags[params.rowIndex][index]) {
                return {background: '#FFFFE0'};
              }
            } else {
              return {
                background: '#fff',
              };
            }
          },
          flex: 1,
        } as ColDef;
      }
    );

    return gridColumns;
  }

  function getTableRows(): RowData[] {
    if (!props.productVersionTable) {
      return [];
    }
    if (!props.productVersionTable.rows) {
      props.productVersionTable.rows = [];
    }

    const tableRows = props.productVersionTable.rows.map((row, index) => {
      return {
        id: generateRowIdFromIndex(index),
        data: row.map(data => data || ''),
      };
    });

    return tableRows;
  }

  function generateRowIdFromIndex(index: number) {
    return props.productVersionTable?.id + '_' + index;
  }

  useEffect(() => {
    setColDefs(getColumns());
    setRows(getTableRows());
  }, [props.productVersionTable]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: false,
    };
  }, []);

  const gridToShowTable = props.productVersionTable ? (
    <AgGridReact
      defaultColDef={defaultColDef}
      rowData={rows}
      columnDefs={colDefs}
    ></AgGridReact>
  ) : (
    ''
  );

  return (
    <>
      <div
        className={'ag-theme-quartz'}
        style={{
          width: '100%',
          height: props.tableHeight ? props.tableHeight : '800px',
          overflow: 'auto',
        }}
      >
        {gridToShowTable}
      </div>
    </>
  );
}
