import {Button, Col, Flex, Menu, MenuProps, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {useHotkeys} from 'react-hotkeys-hook';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {RatingService} from '../service/RatingService';

import {
  BuildTestCaseTree,
  MergeTestCaseTree,
  TestCaseTreeNode,
} from '../control/testcase/TestCaseUIModel';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import {ProductVersion} from '../../product/model/Product';
import {AuthManager} from '../../user/util/AuthManager';
import {ProductVersionSummaryForm} from '../control/ProductVersionSummaryForm';
import {RatingNavigationBar} from '../control/RatingNavigationBar';
import {FlowLogControl} from '../control/testcase/FlowLogControl';
import {TestCaseDetailPanel} from '../control/testcase/TestCaseDetailPanel';
import {TestCaseSummaryForm} from '../control/testcase/TestCaseSummaryForm';
import {FlowLog} from '../model/Rating';
import {IsProductVersionReadOnly} from './ProductUtils';
import TestCase = kot.com.adaptify.rating.admin.model.testcase.TestCase;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import TestCaseLobItem = kot.com.adaptify.rating.admin.model.testcase.TestCaseLobItem;

export interface ProductVersionTestCaseDetailControlProps {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  testCaseId: string;
  authManager: AuthManager;
}

export interface TestExecutionState {
  actualPremium: string;
  status: string;
  output: TestCaseTreeNode | undefined;
  flowLogs: FlowLog[];
  error?: string | undefined;
}

export function ProductVersionTestCaseDetailControl(
  props: ProductVersionTestCaseDetailControlProps
) {
  const [modifyCount, setModifyCount] = useState<number>(0);
  const [testCase, setTestCase] = useState<TestCase | undefined>();
  // TODO limit the size of the stack so it can't grow indefinitely
  const [undoStack, setUndoStack] = useState<TestCaseTreeNode[]>([]);
  const [redoStack, setRedoStack] = useState<TestCaseTreeNode[]>([]);
  const [lobHierarchy, setLobHierarchy] = useState<
    LineOfBusinessHierarchy | undefined
  >(undefined);
  const [productVersion, setProductVersion] = useState<
    ProductVersion | undefined
  >(undefined);
  const [tree, setTree] = useState<TestCaseTreeNode | undefined>(undefined);
  const [testExecutionState, setTestExecutionState] = useState<
    TestExecutionState | undefined
  >(undefined);
  const [selectedTab, setSelectedTab] = useState<string | undefined>(
    'TestCase'
  );

  useEffect(() => {
    const eff = async () => {
      const testCase = await props.productService.GetProductVersionTestCase(
        props.testCaseId
      );
      if (!testCase) {
        handleNewTestCaseFromServer(undefined);
        setLobHierarchy(undefined);
        return;
      }
      handleNewTestCaseFromServer(testCase);
      const lobHierarchy =
        await props.ratingService.GetUtilizedLobHierarchyForProductVersionId(
          testCase.productVersionId
        );
      setLobHierarchy(lobHierarchy);
      const productVersion = await props.productService.GetProductVersion(
        testCase.productVersionId
      );
      setProductVersion(productVersion);
    };
    eff();
  }, [props.testCaseId, modifyCount]);

  useEffect(() => {
    if (!lobHierarchy || !testCase) {
      setTree(undefined);
      return;
    }
    const tree = BuildTestCaseTree(
      testCase.rootRiskItem || undefined,
      lobHierarchy
    );
    setTree(tree);
  }, [lobHierarchy, testCase]);

  async function handleNewTestCaseFromServer(testCase?: TestCase) {
    setTestCase(testCase);
    setUndoStack([]);
    setRedoStack([]);
  }

  function isProductNotOwnedByTenant() {
    return IsProductVersionReadOnly(productVersion, props.authManager);
  }

  function onModified() {
    setModifyCount(modifyCount + 1);
  }

  function isDirty() {
    return undoStack.length > 0;
  }

  function onTestCaseLocalChanged(updated: TestCaseTreeNode) {
    if (tree) {
      setUndoStack([...undoStack, tree]);
    }
    setTree(updated);
  }

  async function save(): Promise<void> {
    if (!tree || !testCase) {
      return;
    }

    const merged = MergeTestCaseTree(tree, testCase);

    const updated =
      await props.productService.UpdateProductVersionTestCase(merged);
    // need to go back to the server after saving because the list might need updating
    // so force reload (esp if it's saving the same table)
    setModifyCount(modifyCount + 1);
    return;
  }

  function canUndo() {
    return undoStack.length > 0;
  }

  function canRedo() {
    return redoStack.length > 0;
  }

  function undo() {
    if (undoStack.length === 0) {
      return;
    }
    const last = undoStack[undoStack.length - 1];
    if (tree) {
      setRedoStack([...redoStack, tree]);
    }
    setTree(last);

    setUndoStack(undoStack.slice(0, undoStack.length - 1));
  }

  function redo() {
    if (redoStack.length === 0) {
      return;
    }
    const last = redoStack[redoStack.length - 1];
    if (tree) {
      setUndoStack([...undoStack, tree]);
    }
    setTree(last);
    setRedoStack(redoStack.slice(0, redoStack.length - 1));
  }

  async function executeTestCase() {
    if (!testCase) {
      return;
    }

    if (isDirty()) {
      await save();
    }

    try {
      const result = await props.ratingService.ExecuteTestCase(testCase.id);
      const resultAsTree =
        result && lobHierarchy && result.output
          ? BuildTestCaseTree(result.output, lobHierarchy)
          : undefined;
      setTestExecutionState({
        actualPremium: result.actualPremium,
        status: result.hasError ? 'Error' : result.passed ? 'Passed' : 'Failed',
        output: resultAsTree,
        error: result.errorMessage,
        flowLogs: result.logs,
      });
    } catch (e: any) {
      setTestExecutionState({
        actualPremium: '',
        status: 'Error',
        error: e.details?.message ?? e.message,
        output: undefined,
        flowLogs: [],
      });
      console.error(e);
      return;
    }
  }

  function handleTabChange(key: string) {
    setSelectedTab(key);
  }

  function createActiveChildControl() {
    if (selectedTab === 'TestCase') {
      return (
        <TestCaseDetailPanel
          lobHierarchy={lobHierarchy}
          rootTreeNode={tree}
          updateRootTreeNode={onTestCaseLocalChanged}
          readonly={false}
        />
      );
    }
    if (selectedTab === 'Output') {
      return (
        <TestCaseDetailPanel
          lobHierarchy={lobHierarchy}
          rootTreeNode={testExecutionState?.output}
          updateRootTreeNode={() => {}}
          readonly
        />
      );
    }
    const flowControls = (testExecutionState?.flowLogs ?? []).map(log => {
      return <FlowLogControl flowLog={log} />;
    });
    return <Flex vertical>{flowControls}</Flex>;
  }

  useHotkeys('mod+s', e => {
    e.preventDefault();
    if (tree) {
      save();
    }
  });

  useHotkeys('mod+z', e => {
    e.preventDefault();
    if (canUndo()) {
      undo();
    }
  });

  // mac os
  useHotkeys('mod+shift+z', e => {
    e.preventDefault();
    if (canRedo()) {
      redo();
    }
  });

  //windows
  useHotkeys('ctrl+y', e => {
    e.preventDefault();
    if (canRedo()) {
      redo();
    }
  });

  const menuStyle = {color: 'black'};
  const allNavItems: MenuProps['items'] = [
    {
      key: 'TestCase',
      label: (
        <Button
          type="link"
          style={menuStyle}
          onClick={() => handleTabChange('TestCase')}
        >
          Inputs
        </Button>
      ),
    },
    /*
    // remove output tab as it's not part of the prouct requirements, it was used to bootstrap verification of the admin rating engine
    {
      key: 'Output',
      label: (
        <Button
          type="link"
          style={menuStyle}
          onClick={() => handleTabChange('Output')}
        >
          Output
        </Button>
      ),
    },
    */
    {
      key: 'Rate Log',
      label: (
        <Button
          type="link"
          style={menuStyle}
          onClick={() => handleTabChange('Rate Log')}
        >
          Rate Log
        </Button>
      ),
    },
  ];
  // only include output tab if there is an output recorded
  const navItems = testExecutionState?.output
    ? allNavItems
    : allNavItems.slice(0, 1);

  const activeTabControl = createActiveChildControl();

  /*
<Col span={3}>
<Button {...ButtonSettings} onClick={props.executeTestCase}>
  Execute Test Case
</Button>
</Col>
*/

  return (
    <Flex vertical gap={20}>
      <div style={{height: '100%', width: '100%'}}>
        <ProductVersionSummaryForm
          productService={props.productService}
          productVersionId={testCase?.productVersionId || ''}
          isDirty={isDirty()}
          hideDescription
        />
      </div>
      <div style={{minHeight: '46px', width: '100%'}}>
        <RatingNavigationBar
          productVersionId={testCase?.productVersionId || ''}
          isDirty={isDirty()}
          selection="TestSuite"
        />
      </div>
      <div
        style={{
          height: '30',
          width: '100%',
          paddingLeft: 30,
          paddingRight: 30,
        }}
      ></div>
      <Row>
        <Col span={4}>
          <Menu
            mode="horizontal"
            selectedKeys={selectedTab ? [selectedTab] : []}
            onSelect={e => {
              setSelectedTab(e.key as string);
            }}
            style={{
              width: '100%',
            }}
            items={navItems}
            disabled={isProductNotOwnedByTenant()}
          ></Menu>
        </Col>
        <Col span={14}>
          <TestCaseSummaryForm
            testCase={testCase}
            actualPremium={testExecutionState?.actualPremium}
            status={testExecutionState?.status}
            executeTestCase={() => executeTestCase()}
            error={testExecutionState?.error}
          />
        </Col>
        <Col span={6}>
          <Flex justify="end" gap={10}>
            <Button onClick={() => executeTestCase()} disabled={!testCase}>
              Execute Test Case
            </Button>
            <Button
              onClick={() => save()}
              disabled={!testCase || !isDirty() || isProductNotOwnedByTenant()}
            >
              Save
            </Button>
          </Flex>
        </Col>
      </Row>

      {activeTabControl}
    </Flex>
  );
}
